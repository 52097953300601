<template>
  <div class="DataTable">
    <data-table-header
      :selected-data-type="selectedDataType"
      :search-input="searchInput"
      :filter-name="filterName"
      style="z-index: 1;"
      @add-button-clicked="createDatasetElement({})"
      @search="handleSearch"
      @dropdown-item-click="(item) => createDatasetElement(item)"
    />
    <div
      ref="dataTable"
      class="DataTable-body DataTable-body-scrollbar"
      @scroll="handleScroll"
    >
      <data-table-body
        ref="dataTable"
        :search-input="searchInput"
        @data-change="(data) => formattedData = data"
      />
      <loading-page-layer
        v-if="isDisplayedLoading"
        size="extraLarge"
        spinner-color="blue"
      />
      <div
        v-if="searchValue.inputValues && !formattedData.length"
        class="DataTable-container"
      >
        <div class="DataTable-container-img" />
        <p class="DataTable-container-message">
          {{ message }}
        </p>
      </div>
    </div>
  </div>
</template>

<script setup>
import { onMounted, computed, watch, ref } from 'vue';
import { useDataStore } from 'SRC/piniaStore/data/data';
import { useRoute } from 'vue-router';
import { useAppStore } from 'SRC/piniaStore/app/app';
import { useEditorStore } from 'SRC/piniaStore/editor/editor';
import DataTableHeader from './DataTableHeader/DataTableHeader.vue';
import DataTableBody from './DataTableBody/DataTableBody.vue';
import utils from 'SRC/views/utils';

const dataStore = useDataStore();
const editorStore = useEditorStore();
const appStore = useAppStore();
const route = useRoute();
const selectedDataTypeId = ref('');
const searchValue = ref({ inputValues: '', attributes: {}, dataTypeFilters: {} });
const dataTable = ref(null);
const isLoading = ref(false);
const message = ref('There are no results that match your search');
const selectedDataType = ref({ name: 'Data' });
const formattedData = ref([]);
const filterName = ref(null);
const datatypes = computed(() => appStore.datatypes);
const isDisplayedLoading = computed(() => isLoading.value);

onMounted(() => {
  dataTable.value.addEventListener('scroll', handleScroll);
  searchValue.value = { inputValues: '', attributes: {}, dataTypeFilters: {} };
  selectedDataTypeId.value = route.params ? route.params.id : '';
});

const searchInput = computed(() => searchValue.value.inputValues);

const fetchDatasetElements = async (dataTypeId, filterName) => {
  let elementsIds ;
  const dataTypesIds = getDataTypeIds(dataTypeId);
  selectedDataType.value = getSelectedDataType(dataTypeId);
  if (filterName) {
    return [];
  } else {
    dataStore.reset();
    elementsIds = await dataStore.fetchDatasetElements(dataTypesIds);
  }
  if (elementsIds && elementsIds.length > 0) {
    await dataStore.addComputedProperties(elementsIds);
  }
};

const getDataTypeIds = (dataTypeId) => {
  if (dataTypeId) {
    return [dataTypeId];
  }
  return Object.keys(datatypes.value);
};

const getSelectedDataType = (dataTypeId) => {
  if (dataTypeId) {
    const dataTypes = Object.values(datatypes.value);
    return dataTypes.find((el) => el.id === dataTypeId);
  }
  return { name: 'Data' };
};

const handleScroll = async () => {
  if (
    dataTable.value.scrollTop + dataTable.value.clientHeight ===
    dataTable.value.scrollHeight &&
    !isLoading.value
  ) {
    isLoading.value = true;
    const isSearchActive = (searchValue.value.inputValues !== '' || Object.keys(searchValue.value.attributes).length > 0
    || Object.keys(searchValue.value.dataTypeFilters).length > 0);
    const elementsIds = await dataStore.loadMoreDatasetElements(isSearchActive, searchValue.value);
    if (elementsIds && elementsIds.length > 0) {
      await dataStore.addComputedProperties(elementsIds);
    }
    isLoading.value = false;
  }
};

const createDatasetElement = async (event) => {
  if (event && event.subItemslist && event.subItemslist.length > 0) {
    return;
  }
  const requestBody = {};
  requestBody.typeId = event.componentName ? event.componentName.typeId : selectedDataType.value.id;
  requestBody.attributes = (event.componentName && event.componentName.typeAttr) ? { type: event.componentName.typeAttr.value } : {};
  const datasetElement = await dataStore.createDatasetElement(requestBody);
  editorStore.openEditor(datasetElement.id);
};

const handleSearch = utils.debounce(async ({query, attributes, filteredDatatypes = [], historyTraces}) => {
  isLoading.value = true;
  const dataTypeIds = filteredDatatypes.length ? filteredDatatypes : Object.keys(datatypes.value);
  let dataTypeFilters;
  if (selectedDataTypeId.value) {
    dataTypeFilters = {[selectedDataTypeId.value]: {}};
  } else {
    dataTypeFilters = dataTypeIds.reduce((acc, id) => {
      acc[id] = {};
      return acc;
    }, {});
  }
  searchValue.value = {inputValues: query, attributes, dataTypeFilters};
  await dataStore.searchDatasetElements(
    {
      query,
      attributes,
      dataTypeFilters,
      historyTraces
    }
  );
  isLoading.value = false;
}, 500);

watch(
  () => route.params.id,
  async (newId) => {
    searchValue.value = {inputValues: '', attributes: {}, dataTypeFilters: {}};
    selectedDataTypeId.value = newId;
    filterName.value = route.query ? route.query.filter : null;
    if (route.path === '/elements') {
      await fetchDatasetElements('',  filterName.value);
      return;
    }
    dataStore.reset();
    const datatypeId = Object.keys(datatypes.value).find((id) => id === newId);
    if (datatypeId) {
      await fetchDatasetElements(newId, filterName.value);
    }
  }, { immediate: true }
);

watch(() => datatypes.value,
  async () => {
    await fetchDatasetElements(selectedDataTypeId.value);
  }, { deep: true }
);

</script>

<style lang="scss" scoped src="./DataTable.scss" />
