<template>
  <div>
    <IobTimeSeriesTableMapInput
      v-if="canUseTimeSeriesData"
      :map="values"
      :initial-value="props.initialValue"
      :final-target="props.finalTarget"
      :horizon-value="props.horizonValue"
      @update-row="handleRowChange"
    />
    <IobTableMapInput
      v-else
      :map="values"
      :initial-value="props.initialValue"
      :final-target="props.finalTarget"
      :horizon-value="props.horizonValue"
      @update-row="handleRowChange"
    />
  </div>
</template>

<script setup>
import { DateArrayGenerator } from './DateArrayGenerator';
import { DatesSorter } from './DatesSorter';
import IobTimeSeriesTableMapInput from '../IobTimeSeriesTableMapInput/IobTimeSeriesTableMapInput.vue';
import IobTableMapInput from '../IobTableMapInput/IobTableMapInput.vue';
import { defineProps, defineEmits, onMounted, ref, watch } from 'vue';
import { getTimeseriesFrequencyAndDate } from '../../../timeSeriesUtils';

const props = defineProps({
  frequency: {
    type: String,
    default: ''
  },
  inputValues: {
    type: Object,
    default: null,
  },
  startDate: {
    type: String,
    default: ''
  },
  endDate: {
    type: String,
    default: ''
  },
  initialValue: {
    type: String,
    default: ''
  },
  finalTarget: {
    type: String,
    default: ''
  },
  horizonValue: {
    type: String,
    default: ''
  },
  timeSeriesFrequency: {
    type: String,
    default: ''
  },
  canUseTimeSeriesData: {
    type: Boolean,
    default: false
  }
});
const values = ref({});
const { frequencyTimeSeries, timeSeriesDate } = getTimeseriesFrequencyAndDate(props.timeSeriesFrequency);

const dateArrayGenerator = ref(
  new DateArrayGenerator(props.startDate, props.endDate, props.frequency, frequencyTimeSeries, timeSeriesDate, props.canUseTimeSeriesData)
);
onMounted(() => {
  generateDates();
});

const generateDates = ()=>{
  let dates = [];
  if (props.inputValues) {
    const frequency = props.canUseTimeSeriesData ? frequencyTimeSeries : props.frequency;
    const datesSorterInstance = new DatesSorter(frequency, Object.keys(props.inputValues));
    dates = datesSorterInstance.generate();
  }
  else {
    dates = dateArrayGenerator.value.generate();
  }
  values.value = dates.reduce((acc, el) => ({ ...acc, [el]: props.inputValues[el] || '' }), {});

};

const handlePropChange = (property, value) => {
  dateArrayGenerator.value[property] = value;
  const dates = dateArrayGenerator.value.generate();
  const newValues = dates.reduce((acc, el) => ({ ...acc, [el]: props.inputValues[el] || '' }), {});
  return newValues;
};
const propsToTriggerDatesGenerate = ['frequency', 'frequencyTimeSeries', 'timeSeriesDate', 'startDate', 'endDate'];
propsToTriggerDatesGenerate.forEach((prop) => {
  watch(
    () => [props[prop]],
    (newValue) => {
      values.value = handlePropChange(prop, newValue);
      emit('change', values.value);
    }
  );
});

const compareObjects = (obj1, obj2) => {
  if (typeof obj1 !== 'object' || typeof obj2 !== 'object') {
    return false;
  }
  const keys1 = Object.keys(obj1);
  const keys2 = Object.keys(obj2);
  if (keys1.length !== keys2.length) {
    return false;
  }
  for (let key of keys1) {
    if (!keys2.includes(key) || obj1[key] !== obj2[key]) {
      return false;
    }
  }
  return true;
};

watch(props.inputValues, (newValue, oldValue) => {
  if (compareObjects(newValue, oldValue)) {
    return;
  }
  values.value = newValue;
});
const emit = defineEmits(['change']);
const handleRowChange = ({ name, value }) => {
  values.value[name] = value;
  emit('change', values.value);
};
</script>

<style></style>
