<template>
  <div>
    <iob-editor
      v-if="datasetElement && dataType"
      :id="datasetElementId"
      style="z-index: 2000"
      :data-type-id="dataType.id"
      :data-type-name="dataType.name"
      :main-panel-attributes="getMainPanelAttributes"
      :central-attributes-panel="getCentralPanelAttributes"
      :data-type-attributes="dataType.attributes"
      :owner="getOwner(datasetElement.attributes.owner)"
      :users="allUsers"
      :selected-data-type-types="selectedDataTypeTypes"
      :eligible-relations-datatypes="eligibleRelationsDatatypes"
      :eligible-child-data-types="eligibleChildDataTypes"
      :eligible-parent-data-types="eligibleParentDataTypes"
      :eligible-children="eligibleChildren"
      :eligible-parents="eligibleParents"
      :eligible-dependencies="eligibleDependencies"
      :children="children"
      :parent="parent"
      :dependencies="dependencies"
      :dataset-element="datasetElement"
      :relation-config-attributes="dataType.editorConfig.relationConfig.attributes"
      :selected-type="selectedType"
      :invalid-attachment-link="invalidAttachmentLink"
      :show-attachment-dropdown="showAttachmentDropdown"
      :attachments-dropdown="attachmentsDropdown"
      :hidden-attributes="hiddenAttributes"
      :hidden-sections="hiddenSections"
      :hidden-tabs="hiddenTabs"
      :has-summary="editorStore.dataType.editorConfig.general.hasSummary"
      :summary-config="editorStore.dataType.editorConfig.general.summaryConfig"
      :previous-dataset-element="previousDatasetElement"
      :levels="levels"
      :can-use-time-series-data="canUseTimeSeriesData"
      :additional-data="additionalData"
      @closeEditor="closeEditor"
      @handleAttributeChange="({ attribute, value }) => handleAttributesChange({ attribute, value })"
      @onClickTypeOption="(data) => fetchEligibleDatasetElements(data)"
      @onClickAddRelations="(data) => fetchEligibleDatasetElements(data)"
      @onClickCreateAsNewTypeName="(data) => createRelationWithNewDatasetElement(data)"
      @onClickAddRelationWithExistingDatasetElement="(data) => createRelationWithExistingDatasetElement(data)"
      @onClickDetailsMenu="executeAction"
      @search="(data) => handleSearch(data)"
      @onClickDetailsItem="openNewElementDetails"
      @onClickPreviousElement="goBackToPreviousElementDetails"
      @onChange:fieldValue="updateAttachmentUrlInput"
      @onClick:dropdownElement="addAttachment"
      @onClick:action="handleAction"
      @onClick:veil="handleAction"
    />
    <div
      v-if="isDialogOpened"
      class="EditorWrapper-dialogBox"
    >
      <iob-dialog-box
        title="Your element won’t be saved"
        content="We won’t save your element if you don’t specify a title"
        submit-action="Discard"
        cancel-action="Go back"
        @submitAction="discardElement"
        @cancelAction="closeDialogBox"
      />
    </div>
    <div
      v-if="openDeleteAttachmentDialog"
      class="EditorWrapper-dialogBox"
    >
      <iob-dialog-box
        id="deleteAttachmentDialog"
        :title="t('attachment.error.popup.title', { attachmentName: attachmentToDelete.title })"
        :content="t('attachment.error.popup.description')"
        :submit-action="t('board.delete.button')"
        :cancel-action="t('board.cancel')"
        @submitAction="handleDeleteAttachment"
        @cancelAction="openDeleteAttachmentDialog=false;"
      />
    </div>
  </div>
</template>

<script setup>
import { useEditorStore } from 'SRC/piniaStore/editor/editor';
import { useAppStore } from 'SRC/piniaStore/app/app';
import { useDataStore } from 'SRC/piniaStore/data/data';
import { useUsersStore } from 'SRC/piniaStore/users/users';
import { useHierarchyStore } from 'SRC/piniaStore/editor/hierarchy/hierarchy';
import {RELATION_TYPES, EDITOR_HIDDEN_FEATURES, MAX_ATTACHMENTS, FEATURES} from 'SRC/globals/constants';
import { computed, onMounted, ref, watch } from 'vue';
import { useRoute } from 'vue-router';
import utils from 'SRC/views/utils';
import { compareObjects, compareProxies, isFeatureEnabled, isValidUrl } from 'SRC/utils/utils';
import { useI18n } from 'vue-i18n';
import attachmentActions from './utils/fetchAttachments';

const route = useRoute();
const editorStore = useEditorStore();
const hierarchyStore = useHierarchyStore();
const usersStore = useUsersStore();
const appStore = useAppStore();
const dataStore = useDataStore();
const { t } = useI18n();
const selectedType = ref(null);
const relationTypes = ref(RELATION_TYPES);
const selectedDataTypeName = ref(null);
const textAttributes = ref({});
const isDialogOpened = ref(false);
const selectedRelation = ref(null);
const previousDatasetElement = ref(null);
const previousElementTimeout = ref(null);
const currentElementTimeOut = ref(null);
const previousTitle = ref(null);
const levels = ref(null);
const invalidAttachmentLink = ref(false);
const attachmentUrlFieldValue = ref('');
const additionalData = ref({});
const openDeleteAttachmentDialog = ref(false);
const attachmentToDelete = ref(null);
const attachmentUrlName = ref('');

const featureToggles = computed(() => appStore.featureToggles);
const canUseTimeSeriesData = computed(() => isFeatureEnabled(featureToggles.value, FEATURES.KPI_HISTORIZATION_FEATURE));
const hiddenAttributes = computed(() =>
  Object.entries(EDITOR_HIDDEN_FEATURES.attributes)
    .filter(([key]) => !isFeatureEnabled(featureToggles.value, key))
    .flatMap(([, values]) => values)
);

const hiddenSections = computed(() =>
  Object.entries(EDITOR_HIDDEN_FEATURES.sections)
    .filter(([key]) => !isFeatureEnabled(featureToggles.value, key))
    .flatMap(([, values]) => values)
);
const hiddenTabs = computed(() =>
  Object.entries(EDITOR_HIDDEN_FEATURES.tabs)
    .filter(([key]) => !isFeatureEnabled(featureToggles.value, key))
    .flatMap(([, values]) => values)
);
const backupStringAttributes = ref({});
const datasetElementId = computed(() => editorStore.datasetElementId);
const datasetElement = computed(() => editorStore.selectedDatasetElement);
const dataType = computed(() => editorStore.dataType);
const allUsers = computed(() => usersStore.users);
const currentUser = computed(() => usersStore.currentUser);
const parent = computed(() => hierarchyStore.parent);
const children = computed(() => hierarchyStore.children);
const dependencies = computed(() => hierarchyStore.dependencies);
const eligibleChildren = computed(() => hierarchyStore.eligibleChildren);
const eligibleParents = computed(() => hierarchyStore.eligibleParents);
const eligibleDependencies = computed(() => hierarchyStore.eligibleDependencies);
const eligibleChildDataTypes = computed(() => hierarchyStore.eligibleChildDataTypes);
const eligibleParentDataTypes = computed(() => hierarchyStore.eligibleParentDataTypes);
const getMainPanelAttributes = computed(() => editorStore.dataType.editorConfig.general.mainAttributesPanel);
const getCentralPanelAttributes = computed(() => editorStore.dataType.editorConfig.general.centralAttributesPanel);
const eligibleRelationsDatatypes = computed(() => editorStore.dataType.editorConfig.general.eligibleRelationsDatatypes);
const selectedDataTypeTypes = computed(() => {
  const dataTypeName = selectedDataTypeName.value;
  const dataTypeAttributes = checkIfDatatypeHasTypeAttribute(dataTypeName);
  return dataTypeAttributes && dataTypeAttributes.enum && dataTypeName ? dataTypeAttributes.enum : [];
});

const showAttachmentDropdown = computed(() => !hiddenAttributes.value.includes('attachments') &&
    (datasetElement.value?.attributes.attachments?.length ?? []) < MAX_ATTACHMENTS);
const listAttributes = computed(() => {
  if (editorStore && editorStore.dataType.attributes) {
    return editorStore.dataType.attributes.map((e) =>
      editorStore.dataType.attributes.find(({ name }) => e.name === name)
    );
  }
  return [];
});
const attachmentsDropdown = computed(() => [
  {
    id: 'attachmentUrl',
    type: 'input',
    placeholder: t('attachment.input.link.placeholder'),
    iconName: 'Link',
    inputSize: 'default',
    hasError: invalidAttachmentLink.value,
    value: attachmentUrlFieldValue.value
  },
  {
    id: 'attachmentName',
    type: 'input',
    placeholder: t('attachment.input.name.placeholder'),
    inputSize: 'default',
    value: attachmentUrlName.value
  },
  {
    type: 'text',
    textStyle: `color: #d34343; display: ${
      invalidAttachmentLink.value ? 'inline' : 'none'
    };`,
    textContent: t('attachment.error.label')
  },
  {
    type: 'button',
    label: t('attachment.dropdown.button.link.label'),
    iconName: 'Link',
    buttonType: 'filled',
    selected: false,
    showLeftIcon: false,
    color: 'primary',
    style: 'width: 100%;',
    disabled: attachmentUrlFieldValue.value.length === 0 || datasetElement.value.attributes?.attachments?.length >= MAX_ATTACHMENTS
  },
  {
    type: 'text',
    textContent: t('attachment.dropdown.link.description')
  }
]);
onMounted(async () => {
  if (!datasetElementId.value) {
    return;
  }
  previousTitle.value = datasetElement.value?.attributes?.title;
  try {
    await hierarchyStore.fetchEligibleDataTypes();
    await hierarchyStore.fetchHierarchicalRelations();
    await hierarchyStore.fetchDatasetElementDependencies();
    await hierarchyStore.fetchRelationTypes();
    await initializeAdditionalData();
  } catch (error) {
    console.error(error);
  }
  backupStringAttributes.value.title = datasetElement.value?.attributes?.title || '';
  levels.value = appStore.levelsTree;
});

watch(
  () => editorStore.previousDatasetElementIds,
  async (ids) => {
    if (ids.length === 0) {
      previousDatasetElement.value = null;
      return;
    }
    const id = ids[ids.length - 1];
    previousDatasetElement.value = await dataStore.fetchDatasetElementById(id);
  }, {immediate: true}
);

const getOwner = (ownerId) => {
  const owner = allUsers.value.find((user) => user.id === ownerId);
  return owner ? owner : currentUser.value;
};

const checkIfDatatypeHasTypeAttribute = (dataTypeName) => {
  const datatype = appStore.getDataTypeByName(dataTypeName);
  return datatype && datatype.attributes.find((attribute) => attribute.name === 'type');
};

const fetchEligibleDatasetElements = async ({ relation, attributes = {}, dataTypeName, searchValue }) => {
  selectedType.value = null;
  selectedDataTypeName.value = dataTypeName;
  selectedRelation.value = relation;
  const typeAttribute = checkIfDatatypeHasTypeAttribute(dataTypeName);

  if (typeAttribute?.enum?.length) {
    selectedType.value = typeAttribute.enum[0].value;
  }

  if (!attributes.type && selectedType.value) {
    attributes.type = selectedType.value;
  }

  if (attributes.type) {
    selectedType.value = attributes.type;
  }

  const datatype = appStore.getDataTypeByName(dataTypeName);
  const searchQuery = searchValue ? `&search=${searchValue}` : '';
  const isDependency = relationTypes.value[2];
  await hierarchyStore.fetchEligibleElementsForRelations(
    {
      type: relation,
      attributes,
      searchQuery,
      ...(isDependency && { dataTypeFilters: {[datatype.id]: {}} })
    }
  );
};

const discardElement = async () => {
  const isDeleted = await dataStore.deleteSelectedDatasetElement(datasetElementId.value);
  if (!isDeleted) {
    return;
  }
  closeDialogBox();
  resetEditorWrapperData();
};

const closeDialogBox = () => {
  isDialogOpened.value = false;
};

const closeEditor = async () => {
  await changeCurrentElementInEdition();
  editorStore.resetPreviousDataEditor();
  editorStore.isCreating = false;
};
const changeCurrentElementInEdition = async () => {
  const currentTitle = backupStringAttributes.value?.title;
  if (!currentTitle?.length) {
    if (editorStore.isCreating) {
      isDialogOpened.value = true;
      return ;
    } else {
      textAttributes.value.title = previousTitle.value;
      backupStringAttributes.value.title = previousTitle.value;
    }
  }
  if (!compareObjects(datasetElement.value?.attributes, backupStringAttributes.value)) {
    await updateTextInputs();
  }
  await dataStore.addFormulasResultsToElements();
  backupStringAttributes.value = {};
  clearTimeout(currentElementTimeOut.value);
  resetEditorWrapperData();
};
const initializeAdditionalData = async () => {
  const attachments = await attachmentActions.fetchAttachments(datasetElement.value?.attributes?.attachments) ?? [];
  additionalData.value = { attachments };
};

async function updateAttrs(attributes) {
  await editorStore.updateDatasetElement(attributes);
  dataStore.updateAttributeInParent(
    datasetElementId.value,
    attributes
  );
  dataStore.updateAttributeInRelatedDatasetElements(
    datasetElementId.value,
    attributes
  );
  await dataStore.addFormulasResultsToElements();
}

async function updateTextInputs() {
  if (Object.keys(textAttributes.value).length > 0) {
    const attributes = {
      ...datasetElement.value.attributes,
      ...textAttributes.value
    };
    await updateAttrs(attributes);
  }
  textAttributes.value = {};
}

const    updateAttachmentUrlInput = ({value, fieldId}) => {
  if (fieldId === 'attachmentUrl') {
    attachmentUrlFieldValue.value = value;
    invalidAttachmentLink.value = false;
  } else if (fieldId === 'attachmentName') {
    attachmentUrlName.value = value;
  }
};

async function updateAttributeOnInputChange(attribute, updatedValue) {
  const attributes = {
    ...datasetElement.value.attributes,
    [attribute]: updatedValue
  };
  updateAttrs(attributes);
}

const handleTimeFrameAttributeChange = ({ attribute, value, previousAttrValue }) => {
  const areEqual = compareProxies(previousAttrValue, value);
  if (areEqual) {
    return;
  }
  if (!value) {
    onInputChange(attribute, {});
    return;
  }
  if (value.type === 'standard') {
    onInputChange(attribute, value);
    return;
  }
  if (previousAttrValue && previousAttrValue['end-date'] && !value['end-date']) {
    onInputChange(attribute, {});
    return;
  }
  if (!value['end-date']) {
    return;
  }
  const body = { ...value };
  if (!body['start-date']) {
    delete body['start-date'];
  }
  onInputChange(attribute, body);
};

const onTextInput = utils.debounce(updateTextInputs, 1000);
const onInputChange = utils.debounce(updateAttributeOnInputChange, 1000);

const isObject = (obj) => typeof obj === 'object' && obj !== null && !Array.isArray(obj);
const handleAttributesChange = async ({ attribute, value }) => {
  const foundAttribute = listAttributes.value.find((attr) => attr.name === attribute);
  const previousAttrValue = datasetElement.value.attributes[attribute];
  const emptyIntAttrValue = foundAttribute.type === 'int' && value.length === 0;
  const isTimestampedValue = foundAttribute.type === 'timestamped_indicator';
  const isIntAttrValue = foundAttribute.type === 'int';
  const isDoubleAttrValue = foundAttribute.type === 'double';
  const emptyDoubleAttrValue = foundAttribute.type === 'double' && value.length === 0;
  const isTimeFrameAttrValue = foundAttribute.type === 'timeframe';

  if (!isObject(value) && (previousAttrValue === value)) {
    return;
  }
  if (isTimeFrameAttrValue) {
    handleTimeFrameAttributeChange({ attribute, value, previousAttrValue });
    return;
  }
  if (foundAttribute.type === 'string' && !foundAttribute.enum) {
    textAttributes.value = { ...textAttributes.value, [attribute]: value };
    backupStringAttributes.value = {...backupStringAttributes.value, ...textAttributes.value};
    onTextInput();
    return;
  }
  let updatedValue = value;
  if (foundAttribute.type === 'level') {
    updatedValue = value.id;
  } else if (isTimestampedValue) {
    updatedValue = {
      value: Number(value),
      timestamp: String(new Date().toISOString()).slice(0, -5)
    };
    onInputChange(attribute, updatedValue);
    return;
  }
  if (isIntAttrValue) {
    if (emptyIntAttrValue) {
      updatedValue = 0;
    }
    onInputChange(attribute, updatedValue);
    return;
  }
  if (isDoubleAttrValue) {
    if (emptyDoubleAttrValue) {
      updatedValue = 0;
    }
    onInputChange(attribute, updatedValue);
    return;
  }
  const attributes = {
    ...datasetElement.value.attributes,
    [attribute]: updatedValue
  };
  updateAttrs(attributes);
};

const updateDatasetElements = (newDatasetElement) => {
  if (newDatasetElement) {
    const datasetElements = dataStore.datasetElements;
    const newDatasetElements = {[newDatasetElement.id]: newDatasetElement, ...datasetElements};
    dataStore.selectedDatasetElement = newDatasetElement;
    dataStore.datasetElements = newDatasetElements;
  }
};
const addAttachment = async () => {
  invalidAttachmentLink.value = !isValidUrl(attachmentUrlFieldValue.value);
  if (invalidAttachmentLink.value) {
    return;
  } else {
    if (additionalData.value?.attachments?.length >= MAX_ATTACHMENTS) {
      return console.error('Max attachments limit reached');
    }
    const attachment = await attachmentActions.createUrlAttachment(
      attachmentUrlFieldValue.value,
      attachmentUrlName.value ? attachmentUrlName.value : attachmentUrlFieldValue.value,
      datasetElementId.value,
      datasetElement.value?.attributes);
    if (!attachment) {
      return;
    }
    const attachmentIds = [...(datasetElement.value?.attributes?.attachments ?? []), attachment.id];
    const attributes = {
      ...datasetElement.value?.attributes,
      attachments: attachmentIds
    };
    await updateAttrs(attributes);
    await initializeAdditionalData();
    attachmentUrlFieldValue.value = '';
    attachmentUrlName.value = '';

  }
};
const handleAction = async ({ event, attachment }) => {
  switch (event) {
  case 'openNewTab':
    handleOpenNewTab(attachment.url);
    break;
  case 'delete':
    openDeleteAttachmentDialog.value = true;
    attachmentToDelete.value = attachment.id;
    break;
  }
};
const handleOpenNewTab = (url) => {
  if (url) {
    window.open(url, '_blank');
  } else {
    console.error('No URL found');
  }
};
const handleDeleteAttachment = async () => {
  await attachmentActions.deleteAttachment(attachmentToDelete.value);
  const attachmentIds = [...(datasetElement.value.attributes?.attachments || [])];
  const index = attachmentIds.indexOf(attachmentToDelete.value);
  if (index > -1) {
    attachmentIds.splice(index, 1);
  }
  await updateAttrs({
    ...datasetElement.value.attributes,
    attachments: attachmentIds
  });
  await initializeAdditionalData();
  openDeleteAttachmentDialog.value = false;
};
const createRelationWithNewDatasetElement = async ({ relation, body }) => {
  const dataTypeId = appStore.getDataTypeByName(body.dataTypeName).id;
  delete body.dataTypeName;
  body = {
    attributes: {
      type: selectedType.value,
      title: body.title,
      owner: currentUser.value.id
    },
    dataTypeId
  };
  let newDatasetElement;
  if (relation === relationTypes.value[0]) {
    return;
  }
  if (relation === relationTypes.value[2]) {
    newDatasetElement = await hierarchyStore.createDependencyWithNewDatasetElement(body);
    if (!shouldUpdateDatasetElements(newDatasetElement)) {
      return;
    }
    updateDatasetElements(newDatasetElement);
    return;
  }
  newDatasetElement = await hierarchyStore.createRelationFromNewElement(body);
  if (!shouldUpdateDatasetElements(newDatasetElement)) {
    return;
  }
  updateDatasetElements(newDatasetElement);
};

const shouldUpdateDatasetElements = (newDatasetElement) =>
  (newDatasetElement && newDatasetElement.typeId === route.params.id) || route.path === '/elements';

const createRelationWithExistingDatasetElement = async ({
  relation,
  eligibleDatasetElementId
}) => {
  if (relation === relationTypes.value[2]) {
    await hierarchyStore.createDependencyWithExistingDatasetElement({
      eligibleDatasetElementId
    });
    return;
  }
  await hierarchyStore.createRelationsFromExistingElements({
    relation,
    eligibleDatasetElementId
  });
};

const executeAction = async ({ action, relatedDatasetElementId, relationId, relationType}) => {
  if (action === 'deleteAction') {
    await hierarchyStore.deleteRelations({relationId, relatedDatasetElementId, relationType});
    return ;
  }
  if (action === 'unlinkAction') {
    await hierarchyStore.unlinkRelationalElements({relationId, relatedDatasetElementId, relationType});
  }
};

const handleSearch = utils.debounce(async (searchValue) => {
  let attributes = {};
  if (selectedType.value) {
    attributes = { type: selectedType.value };
  }
  await fetchEligibleDatasetElements({
    relation: selectedRelation.value,
    attributes,
    dataTypeName: selectedDataTypeName.value,
    searchValue
  });
}, 500);

const openNewElementDetails = ({id}) => {
  editorStore.addPreviousDatasetElement(datasetElementId.value);
  resetEditorWrapperData();
  currentElementTimeOut.value = setTimeout(() => editorStore.openEditor(id));
};

const goBackToPreviousElementDetails = async () => {
  await changeCurrentElementInEdition();
  previousElementTimeout.value = setTimeout(() => editorStore.openPreviousDetailsEditor());
};

const resetEditorWrapperData = () => {
  hierarchyStore.resetHierarchicalRelations();
  editorStore.closeEditor();
};
</script>
<style lang="scss" scoped src="./EditorWrapper.scss" />
