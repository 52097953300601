<template>
  <div>
    <IobTableMapDatesWrapper
      :frequency="frequency"
      :time-series-frequency="timeSeriesFrequency"
      :input-values="inputValues"
      :start-date="startDate"
      :end-date="endDate"
      :initial-value="initialValue"
      :final-target="props.finalTarget"
      :horizon-value="props.horizonValue"
      :can-use-time-series-data="props.canUseTimeSeriesData"
      @change="emit('change', $event)"
    />
  </div>
</template>

<script setup>
import { defineEmits, defineProps, ref, watch } from 'vue';
import IobTableMapDatesWrapper from '../IobTableMapDatesWrapper/IobTableMapDatesWrapper.vue';
import { DateRangeGenerator } from './DateRangeGenerator';
const emit = defineEmits(['change']);
const props = defineProps({
  type: {
    type: String,
    default: 'custom',
  },
  period: {
    type: String,
    default: '1',
  },
  startDate: {
    type: String,
    default: '',
  },
  endDate: {
    type: String,
    default: '',
  },
  frame: {
    type: String,
    default: 'Q',
  },
  year: {
    type: String,
    default: '',
  },
  frequency: {
    type: String,
    default: '',
  },
  initialValue: {
    type: String,
    default: ''
  },
  inputValues: {
    type: Object,
    default: null,
  },
  finalTarget: {
    type: String,
    default: ''
  },
  horizonValue: {
    type: String,
    default: ''
  },
  timeSeriesFrequency: {
    type: String,
    default: ''
  },
  canUseTimeSeriesData: {
    type: Boolean,
    default: false
  }
});
const dateRangeGenerator = ref(
  new DateRangeGenerator({
    type: props.type,
    startDate: props.startDate,
    endDate: props.endDate,
    period: props.period,
    frame: props.frame,
    year: props.year,
  }))
const res = dateRangeGenerator.value.generate();
const startDate = ref(res.startDate || props.startDate);
const endDate = ref(res.endDate || props.endDate);

const refreshDates = () => {
  const res = dateRangeGenerator.value.generate();
  startDate.value = res.startDate;
  endDate.value = res.endDate;
}

const handlePropChange = (property, value) => {
  dateRangeGenerator.value[property] = value;
  refreshDates();
}
const propsToTriggerDatesGenerate = ['endDate', 'period', 'frame', 'year', 'type'];
propsToTriggerDatesGenerate.forEach((prop) => {
  watch(() => props[prop],
    (newValue) => {
      handlePropChange(prop, newValue)
    }
  )
})

</script>
