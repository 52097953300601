/* eslint-disable max-lines */
export const BOARD_SIZE_3A0 = {
  width: 7560,
  height: 3600
};

export const BOARD_SIZE = BOARD_SIZE_3A0;

export const BOARD_SIZE_DEFAULT = BOARD_SIZE_3A0;

export const BOARD_SIZES = {
  BOARD_SIZE_3A0
};

export const DEFAULT_BOARD_ELEMENT_SIZE = {
  height: '252',
  width: '332'
};

export const DEFAULT_WIDGET_SIZE = {
  height: '48',
  width: '100'
};

export const INPUT_KEYBOARD = {
  ENTER: 'Enter',
  ESCAPE: 'Escape'
};

export const VISUALIZATION_DEF_GRID_SIZE = {
  cols: 8,
  rows: 6
};

export const ZOOM = {
  DEFAULT_LEVEL: 0.17,
  LEVEL_MIN: 0.1,
  LEVEL_MAX: 3,
  LEVEL_OF_DETAIL_THRESHOLD: 0.6,
  PRECISION: 4,
  STEP_MULTIPLIER: 1.4,
  WHEEL_DELTA_RATIO: 0.001
};

export const VIEW_MARGIN = {
  HORIZONTAL: 15,
  VERTICAL: 15
};

export const MAIN_MENU_HEIGHT = 80;

export const INTERACTION_MODES = {
  PANNING: 'panning',
  SELECT: 'select',
  LINKS: 'links'
};
export const MUTATION_TYPES = {
  ERASE_PREVIOUS: 'erase_previous',
  APPEND_TO_PREVIOUS: 'append_to_previous'
};
export default {
  BOARD_SIZE,
  BOARD_SIZE_3A0,
  BOARD_SIZE_DEFAULT,
  BOARD_SIZES,
  INPUT_KEYBOARD,
  INTERACTION_MODES,
  VISUALIZATION_DEF_GRID_SIZE,
  ZOOM,
  VIEW_MARGIN,
  MAIN_MENU_HEIGHT,
  MUTATION_TYPES
};

export const VERSION_DATETIME_OPTIONS = {
  day: 'numeric', month: 'short', hour: 'numeric',
  minute: 'numeric'
};

export const USER_TECHNICAL_TYPE = 'technical';

export const VERSIONS_MENU_ITEMS = [
  {
    id: 1,
    title: 'Board versions',
    submenu: [
      {
        id: 2,
        title: 'Create a version',
        componentName: 'CreateVersion',
        action: 'createVersion'
      },
      {
        id: 3,
        title: 'Show version history',
        componentName: 'VersionHistory',
        action: 'toggleComponents'
      }
    ]
  },
  {
    id: 4,
    title: 'Board history',
    componentName: 'History',
    action: 'history'
  }
];

export const MODAL_TEXTS = {
  REMOVE_HIERARCHY_TITLE: 'Are you sure you want to remove this hierarchy?',
  REMOVE_HIERARCHY_DESCRIPTION: 'All users won\'t be able to use levels anymore.',
  CANCEL_BUTTON: 'Cancel',
  REMOVE_BUTTON: 'Remove'
};

export const DROPDOWN_MENU_TEXTS = {
  DOWNLOAD: 'Download',
  REMOVE_HIERARCHY: 'Remove hierarchy'
};

export const VERSION_DETAILS_ITEMS = [
  {
    id: 1,
    title: 'Edit information',
    action: 'editBoardVersion'
  },
  {
    id: 2,
    title: 'Restore this version',
    action: 'restoreBoardVersion'
  },
  {
    id: 3,
    title: 'Create new board from this version',
    action: 'createBoardfromVersion'
  }
];

export const DEFAULT_USER_COLOR = `${`#${(Math.floor(Math.random() * 0xFFFFFF)).toString(16)}`}`;

export const VERSION_CATEGORIES = ['recentVersion', 'oldVersion', 'currentVersion'];

export const VERSION_MODES = ['createVersion', 'editVersion', 'markVersion', 'afterCreation'];

export const COMPARAISON_DATE_OPTIONS = { month: 'short', day: 'numeric', year: 'numeric' };

export const PAGE_SIZE = 50;
export const HISTORY_PAGE_SIZE = 10;

export const MIN_ZOOM_LEVEL = 0.4;
export const FEEDBACK_BOTTOM_POSITION = -2;
export const FEEDBACK_LEFT_POSITION = -4;
export const FEEDBACK_INFO_BOX_TOP_POSITION = -46;

// Minimum hours count to display the number of days left until a version reaches 30 days
export const MINIMUM_HOURS = 24;

// Maximum days a version is alive if not marked
export const MAXIMUM_DAYS_UNMARKED_VERSION = 30;

export const LOCALHOST_API = 'localhost';

export const CHILDREN_COMPONENT_EMITTED_EVENTS = ['checked-child', 'keyword-change', 'existing-element-click', 'remove-selectors',
  'dataset-element-change', 'childrenToAdd-change'];

export const MAX_HEIGHT_HIDDEN_AVATARS = 300;
export const MAX_ELEMENTS_IN_DROPDOWN_LIST = 6;

export const MAX_HEIGHT_CHILDREN_DROPDOWN_LIST = 245;

export const FORMULA_TOP_EDITOR_EQUATION = {
  NUMERATOR: 139.809,
  EXPONENT: 0.9043,
  OFFSET: -96.72
};
export const FORMULA_TOP_CTX_MENU_EQUATION = {
  EXP_POW: -2.70052,
  EXP_TIMES: -149.622,
  OFFSET: -33.4254
};
export const e = 2.718;
export const MAX_ZL_SCALE_EDITOR = 0.25;

export const UNKNOWN_USER = {
  firstname: 'Unknown',
  lastname: 'User',
  picture: ''
};

export const DEFAULT_AVATAR_TEXT_COLOR = '#000';
export const BOARD_ELEMENT_EDITOR_TABS = ['General', 'Relations', 'History'];

export const COLOR_PALETTE = [
  '#D0D0D0',
  '#F3B1D1',
  '#D2BBFB',
  '#B1D1F7',
  '#C8E999',
  '#FFE97B',
  '#FFC179',
  '#FF8C75',
  '#FFC4B7',
  '#FFDEB8',
  '#FFF3B9',
  '#E1FDB9',
  '#D7E8FD',
  '#EBE2FD',
  '#FFE1F0',
  '#FFFFFF',
  '#FF8C75',
  '#FFC179',
  '#FFE97B',
  '#C8E999',
  '#B1D1F7',
  '#D2BBFB',
  '#F3B1D1',
  '#D0D0D0',
  '#FB593E',
  '#FFA444',
  '#FFDF48',
  '#AFD67A',
  '#8CB9F1',
  '#B997FA',
  '#E784B3',
  '#9E9E9E',
  '#F72B18',
  '#FF8822',
  '#FFD62E',
  '#97C35F',
  '#6AA2EC',
  '#A175FA',
  '#D95A96',
  '#2D2D2D'
];

export const SEARCH_MENU_HEIGHT = 220;

export const DEFAULT_CARD_FIELDS = [
  {
    startIcon: 'Text',
    value: 'Title',
    disabled: true,
    style: 'width: 330px;',
    details: {}
  }
];

export const FIELD_TYPES = [
  {
    iconName: 'Hash',
    label: 'Number',
    iconColor: '#999B9E'
  },
  {
    iconName: 'TextField',
    label: 'Text',
    iconColor: '#999B9E'
  },
  {
    iconName: 'Date',
    label: 'Date',
    iconStroke: '#999B9E'
  },
  {
    iconName: 'Select',
    label: 'Select',
    iconColor: '#999B9E',
    iconStroke: '#999B9E'
  },
  {
    iconName: 'User',
    label: 'User',
    iconStroke: '#999B9E'
  }
];

export const FIELD_MENU_ITEMS = [
  {
    id: 1,
    title: 'Edit',
    action: 'editField'
  },
  {
    id: 2,
    title: 'Delete',
    textColor: '#f15454',
    action: 'deleteField'
  }
];

export const LINKS_TYPES = {
  HierarchicalRelationType: 'HIERARCHICAL_RELATION',
  DependencyType: 'DEPENDENCY'
};

export const LEADER_LINE_OPTIONS = {
  color: '#2D6BFF',
  outlineColor: 'rgba(30, 130, 250, 0.5)',
  size: 1,
  dash: false,
  endPlug: 'arrow2',
  endPlugSize: 2,
  allowedSockets: ['right', 'left']
};
export const MAX_DISPLAYED_VIEWTYPES = 10;
export const MINIMUM_DISPLAYED_VIEWTYPES = 1;

export const DEPENDECIES_DIRECTIONS = {inward: 'INWARD', outward: 'OUTWARD'};

export const VISUAL_LINKS_ACTIONS = {
  TYPEANDCOLOR: 'Type and color',
  THICKNESSANDSTYLE: 'Stroke style',
  DIRECTION: 'Reverse direction',
  DELETE: 'Remove relation'
};

export const DEFAULT_VISUAL_LINK_MENU_SIZE = {
  height: 48,
  width: 228
};

export const STROKESTYLE_VALUES = {
  solid: false,
  dash: { len: 4, gap: 4, animation: false },
  dot: { len: 2, gap: 5, animation: false }
};

export const STROKESTYLE_MENU = {
  Thickness: [
    {
      iconName: 'MinimumStroke',
      options: {
        size: 1,
        endPlugSize: 2
      }
    },
    {
      iconName: 'MediumStroke',
      options: {
        size: 2,
        endPlugSize: 1
      }
    },
    {
      iconName: 'MaximumStroke',
      options: {
        size: 3,
        endPlugSize: 1
      }
    }
  ],
  Style: [
    {
      iconName: 'MinimumStroke',
      options: {
        dash: STROKESTYLE_VALUES.solid
      }
    },
    {
      iconName: 'Dash',
      options: {
        dash: STROKESTYLE_VALUES.dash
      }
    },
    {
      iconName: 'DottedLine',
      options: {
        dash: STROKESTYLE_VALUES.dot
      }
    }
  ]
};

export const boardHeaderConfig = [
  { name: 'boardName', label: 'Board Name', type: 'title', width: '40%', sortable: false, sortColumnValue: 'name' },
  { name: 'level', label: 'level', type: 'user', sortable: false, sortColumnValue: 'level' },
  { name: 'creator', label: 'creator', type: 'user', sortable: false, sortColumnValue: 'creator'},
  { name: 'activity', label: 'activity', type: 'label', sortable: false, sortColumnValue: 'modificationDate'},
  { name: 'more', label: '', type: 'icons', sortable: false, sortColumnValue: ''}
];

export const DATA_VIEW_HEADERS_CONFIG = {
  headers: [
    { name: 'TYPE', label: 'TYPE', type: 'titleWithIconShape', maxWidth: '200px', minWidth: '120px', isChecked: true, sortable: false},
    { name: 'ID', label: 'ID', type: 'label', maxWidth: '200px', minWidth: '80px', sortable: false},
    { name: 'TITLE', label: 'TITLE', type: 'title', maxWidth: '800px', minWidth: '400px', width: 0, sortable: false},
    { name: 'STATUS', label: 'STATUS', type: 'badge', maxWidth: '400px', minWidth: '200px', sortable: false},
    { name: 'OWNER', label: 'OWNER', type: 'user', maxWidth: '400px', minWidth: '100px', sortable: false },
    { name: 'PROGRESSION', label: 'PROGRESSION', type: 'progress', maxWidth: '400px', minWidth: '100px', sortable: false}
  ],
  sortField: 'ID',
  sortDirection: 'asc'
};

export const MAX_ELEMENTS_PER_PAGE = 100;

export const dataTypesIcons = {
  Objective: {
    types: ['CI Goal', 'CBN', 'Foundational Element'],
    icons: {
      parent: 'Goal',
      children: 'Goal'
    },
    color: 'other'
  },
  Project: {
    icons: {
      parent: 'FolderOpen',
      children: 'FolderOpen'
    },
    color: 'primary'
  },
  Issue: {
    icons: {
      parent: 'AlertTriangle',
      children: 'AlertTriangle'
    },
    color: 'alert'
  },
  Task: {
    icons: {
      parent: 'ClipBoardList',
      children: 'ClipBoardList'
    },
    color: 'success'
  },
  KPI: {
    icons: {
      parent: 'TrendingUp',
      children: 'TrendingUp'
    },
    color: 'secondary'
  },
  Action: {
    icons: {
      parent: 'MousePointerClick',
      children: 'MousePointerClick'
    },
    color: 'warning'
  }
};

export const RELATION_TYPES = ['parent', 'children', 'dependency'];

export const DATA_TYPES_NAMES = {
  OBJECTIVE: 'Objective',
  PROJECT: 'Project',
  ISSUE: 'Issue',
  ACTION: 'Action',
  KPI: 'KPI',
  TASK: 'Task',
  LEVEL: 'Level'
};

export const DATA_TYPES_NAMES_SPACE = {
  ELEMENT: '/element-data-types/opex',
  SYSTEM: '/system-data-types/opex'
};

export const HIERARCHY = {
  IMPORT_PATH: '/hierarchy-import',
  VIEW_PATH: '/hierarchy',
  LEVELS_COLORS: [
    { textColor: '#1D51CE', bgColor: '#DFE8FF', badgeColor: 'primary' },
    { textColor: '#774D0D', bgColor: '#FDE491', badgeColor: 'warning' },
    { textColor: '#A43434', bgColor: '#FBE3E3', badgeColor: 'alert' },
    { textColor: '#00676B', bgColor: '#DAECEC', badgeColor: 'success' },
    { textColor: '#644DA1', bgColor: '#EBE5FB', badgeColor: 'other' },
    { textColor: '#5D587B', bgColor: '#E8E7EC', badgeColor: 'secondary' }
  ]
};

export const LEVELS = [
  'L6 M&S',
  'L5 Specialty Care',
  'L4 Dupixent',
  'L3 Waterford',
  'L3 Geel',
  'L2.5',
  'L2 Waterford/APU',
  'L2 Geel/APU',
  'L1 Waterford/APU/PCU',
  'L1 Geel/APU/PCU',
  'L0 Waterford/APU/PCU/Shift',
  'L0 Geel/APU/PCU/Shift'
];

export const BOARD_DETAILS_MENU_ITEMS = [
  { id: 'deleteAction', text: 'Delete board', type: 'menu' }
];

export const ROUTINE_ICONS = {
  'Qcd': { color: 'success', backgroundImg: '02' },
  'RefreshCcwDot': { color: 'info', backgroundImg: '02' },
  'Split': { color: 'warning', backgroundImg: '02' },
  'PersonWalking': { color: 'other', backgroundImg: '02' },
  'TrendingUp': { color: 'alert', backgroundImg: '02' },
  'Gauge': { color: 'secondary', backgroundImg: '01' },
  'Route': { color: 'other', backgroundImg: '01' },
  'BadgeCheck': { color: 'success', backgroundImg: '02' },
  'CircuitBoard': { color: 'info', backgroundImg: '01' },
  'ArrowUpRightFromSquare': { color: 'elevation', backgroundImg: '02' }
};

export const UNASSIGNED = 'Unassigned';

export const CONTENT_TYPES = {
  JSON: 'application/json',
  EXCEL: 'application/vnd.ms-excel',
  PDF: 'application/pdf',
  JPEG: 'image/jpeg',
  PNG: 'image/png',
  SVG: 'image/svg+xml',
  CSV: 'text/csv',
  PLAIN: 'text/plain',
  MULTIPART_FORM_DATA: 'multipart/form-data'
};
