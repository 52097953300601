<template>
  <div class="DataTable-header">
    <iob-header-action
      :title="selectedDataType.name"
      :sub-title="elementsCount"
      search-placeholder="search an element.."
      button-icon="Plus"
      :search-input="searchInput"
      :sort-button="false"
      :filter-button="true"
      :filter-details="filtersDetailsComputed"
      :list-view-button="false"
      @button-clicked="handleAddButtonClick"
      @search="(value) => handleSetQuery(value)"
      @filter="handleToggleModal"
    />
    <outside-click-listener
      v-if="menuShown"
      @outside-click="hideMenu"
    >
      <iob-dropdown
        v-if="dataTypesFormattedList.length > 0"
        :items="dataTypesFormattedList"
        :style="dropdownStyle"
        @dropdown-element-item="handleDropdownItemClick"
      />
    </outside-click-listener>
    <outside-click-listener
      v-show="filterModal"
      @outside-click="hideFilterModal"
    >
      <iob-filter-modal
        v-if="currentUserId"
        :key="refreshKey"
        :style="computeFilterModalStyle()"
        :items="attributes"
        :initial-selected-items="selectedItems"
        :initial-quick-filters="quickFilters"
        :initial-other-filters="otherFilters"
        :default-quick-filters="computeDefaultQuickFilters"
        @setFilter="handleSetFilters"
        @close="handleToggleModal"
      />
    </outside-click-listener>
  </div>
</template>

<script setup>
import { computed, ref, defineProps, defineEmits, watch, onMounted } from 'vue';
import { useDataStore } from 'SRC/piniaStore/data/data';
import { useAppStore } from 'SRC/piniaStore/app/app';
import { useRoute } from 'vue-router';
import { useUsersStore } from 'SRC/piniaStore/users/users';
import { mergeAttributes, filterDataOptionsByType, defaultQuickFilters, getHistoryTraces, ATTRIBUTES_WITH_HISTORY } from 'SRC/views/DataView/utils';

const dataStore = useDataStore();
const appStore = useAppStore();
const userStore = useUsersStore();
const menuShown = ref(false);
const filterModal = ref(false);
const refreshKey = ref(1);
const query = ref('');
const filterDetails = ref({
  label: 'Filter',
  showLeftIcon: true,
  icon: 'ListFilter'
});
const filters = ref({});
const dropdownStyle = ref({
  position: 'fixed',
  zIndex: '1000',
  transform: 'translateY(-4px)',
  width: '208px'
});
const filterModalStyle = ref({
  position: 'fixed',
  zIndex: '1000',
  width: '580px'
});
const route = useRoute();
const currentUserId = computed(() => (userStore.currentUser?.id));
const computeDefaultQuickFilters = computed(() => {
  if (!currentUserId.value) {
    return {};
  }
  return defaultQuickFilters.map((el) => {
    if (el.hasCurrentUser) {
      return { ...el, values: [currentUserId.value] };
    }
    return el;
  });
});
const dataTypes = computed(() => props.selectedDataType.id ? [props.selectedDataType] :
  Object.values(appStore.datatypes));
const attributes = computed(() => filterData());
const elementsCount = computed(() => Object.values(dataStore.datasetElements) && Object.values(dataStore.datasetElements).length);
const dataTypesFormattedList = computed(() => {
  const isADataTypeSelected = !!props.selectedDataType.id;
  if (isADataTypeSelected) {
    return getTypeAttrList(props.selectedDataType);
  }
  return Object.values(appStore.datatypes).map((el) => {
    const submenu = getTypeAttrList(el);
    return {
      text: `${el.name}`,
      state: 'default',
      type: 'menu',
      action: 'createDatasetElement',
      componentName: {
        typeId: el.id
      },
      subItemslist: submenu
    };
  });
});
const allUsers = computed(() => userStore.users);
const allLevels = computed(() => appStore.levelsTree || {});
const filtersDetailsComputed = computed(() => {
  if (Object.values(filters.value).length) {
    return {
      ...filterDetails.value,
      label: `Filter : ${Object.values(filters.value).length}`,
      isFocused: true
    };
  }
  return filterDetails.value;

});

const emits = defineEmits(['add-button-clicked', 'dropdown-item-click', 'search']);

const props = defineProps({
  selectedDataType: {
    type: Object,
    default: () => ({ name: 'Data' })
  },
  searchInput: {
    type: String,
    default: ''
  },
  filterName: {
    type: String,
    default: ''
  }
});

watch(() => route.params.id, () => {
  computeDropdownMenuStyle();
  hideMenu();
  hideFilterModal();
  refreshFiltersModal();
});

const quickFilters = ref({});
const otherFilters = ref({});
const selectedItems = computed(() => filterSelectedItems());
const filterSelectedItems = () => {
  let selectedItems = [];
  if (!otherFilters.value) {
    return selectedItems;
  }
  attributes.value.forEach((item) => {
    const attValues = otherFilters.value[item.name];
    if (attValues && attValues.length) {
      const items = (item.options || []).filter((option) => {
        const data = option.withID ? option.id : (option.date || option.text);
        return attValues.some((filter) => filter === data);
      });
      selectedItems = [...selectedItems, ...items];
    }
  });
  return selectedItems;
};
onMounted(() => {
  const name = props.filterName;
  quickFilters.value = dataStore?.quickFilters[name];
  otherFilters.value = dataStore?.otherFilters[name];
});

const refreshFiltersModal = () => {
  refreshKey.value += 1;
  filters.value = {};
  quickFilters.value = {};
  otherFilters.value = {};
};
const hideMenu = () => {
  menuShown.value = false ;
};
const hideFilterModal = () => {
  filterModal.value = false;
};
const toggleMenu = () => (menuShown.value = !menuShown.value);
const handleToggleModal = () => (filterModal.value = !filterModal.value);
const handleAddButtonClick = () => {
  computeDropdownMenuStyle();
  if (dataTypesFormattedList.value && dataTypesFormattedList.value.length > 0) {
    return toggleMenu();
  }
  emits('add-button-clicked');
};
const handleDropdownItemClick = ({ item }) => {
  if (!item.action || item.action === '') {
    return;
  }
  emits('dropdown-item-click', item);
};
const handleSetQuery = (value) => {
  query.value = value;
  handleSearchValues(value, filters.value);
};
const handleSetFilters = (attributes) => {
  quickFilters.value = {};
  otherFilters.value = {};
  filters.value = attributes;
  handleSearchValues(query.value, attributes);
};
const currentDate = new Date();
const handleSearchValues = (query, attributes) => {
  const nameAttrHistory = Object.keys(attributes).find((name) => Object.keys(ATTRIBUTES_WITH_HISTORY).includes(name));
  const {['family-type']: _, [nameAttrHistory]: historyTracesValues, ...rest} = attributes;
  const historyTraces = getHistoryTraces(nameAttrHistory, historyTracesValues, dataTypes.value, currentDate);
  emits('search', {query, attributes: rest, filteredDatatypes: _, historyTraces});
};
const getTypeAttrList = (type) => {
  const typeAttr = type.attributes.find((attr) => attr.name === 'type');
  if (!typeAttr) {
    return [];
  }
  const submenu = typeAttr ? typeAttr.enum.map((val) => ({
    id: val.id,
    text: val.value,
    state: 'default',
    type: 'menu',
    action: 'createDatasetElement',
    componentName: {
      typeId: type.id,
      typeAttr: val
    }
  })) : [];
  return submenu;
};
const filterData = () => {
  const attributes = dataTypes.value.reduce((acc, dataType) => {
    const {centralAttributesPanel, mainAttributesPanel} = dataType.editorConfig.general;
    const filteredAttributesPanel = [...centralAttributesPanel.general.attributes, ...mainAttributesPanel];
    const mergedAttributes = mergeAttributes(dataType.attributes, filteredAttributesPanel);
    acc = {...acc, ...mergedAttributes};
    return  acc;
  }, {});
  const id = props.selectedDataType.id;
  const attrOptionsMap = filterDataOptionsByType(Object.values(attributes), {allUsers, allLevels, dataTypes}, id);
  return attrOptionsMap ;
};
const computeDropdownMenuStyle = () => {
  const leftHeaderSlice = document.querySelector('.IobHeaderAction-left-titleGroup');
  const element = document.querySelector('.IobHeaderAction-left');
  if (!element) {
    return;
  }
  const gap = window.getComputedStyle(element)?.getPropertyValue('gap');
  if (leftHeaderSlice === null || gap === null) {
    return;
  }
  const leftHeaderSliceRectangles = leftHeaderSlice.getBoundingClientRect();
  const leftOffset = leftHeaderSliceRectangles.left + leftHeaderSliceRectangles.width + parseFloat(gap);
  dropdownStyle.value = { ...dropdownStyle.value, left: `${leftOffset}px` };
};
const computeFilterModalStyle = () => {
  const element = document.querySelector('.IobHeaderAction-right-searchAndSort');
  const width = parseInt(filterModalStyle.value.width);
  if (!element || !width) {
    return;
  }
  const elementRectangles = element.getBoundingClientRect();
  const topOffset = elementRectangles.top + elementRectangles.height + 5;
  const leftOffset = (elementRectangles.left - width) + 10;
  return { ...filterModalStyle.value, top: `${topOffset}px`, left: `${leftOffset}px` };
};

</script>

<style lang="scss" scoped src="../DataTable.scss" />
