<template>
  <div>
    <iob-editor
      v-if="datasetElement && dataType"
      :id="datasetElementId"
      style="z-index: 2000"
      :data-type-id="dataType.id"
      :data-type-name="dataType.name"
      :main-panel-attributes="getMainPanelAttributes"
      :central-attributes-panel="getCentralPanelAttributes"
      :data-type-attributes="dataType.attributes"
      :owner="getOwner(datasetElement.attributes.owner)"
      :users="allUsers"
      :selected-data-type-types="selectedDataTypeTypes"
      :eligible-relations-datatypes="eligibleRelationsDatatypes"
      :eligible-child-data-types="eligibleChildDataTypes"
      :eligible-parent-data-types="eligibleParentDataTypes"
      :eligible-children="eligibleChildren"
      :eligible-parents="eligibleParents"
      :eligible-dependencies="eligibleDependencies"
      :children="children"
      :parent="parent"
      :dependencies="dependencies"
      :dataset-element="datasetElement"
      :relation-config-attributes="dataType.editorConfig.relationConfig.attributes"
      :selected-type="selectedType"
      :has-summary="editorStore.dataType.editorConfig.general.hasSummary"
      :summary-config="editorStore.dataType.editorConfig.general.summaryConfig"
      :previous-dataset-element="previousDatasetElement"
      :levels="levels"
      @closeEditor="closeEditor"
      @handleAttributeChange="({ attribute, value }) => handleAttributesChange({ attribute, value })"
      @onClickTypeOption="(data) => fetchEligibleDatasetElements(data)"
      @onClickAddRelations="(data) => fetchEligibleDatasetElements(data)"
      @onClickCreateAsNewTypeName="(data) => createRelationWithNewDatasetElement(data)"
      @onClickAddRelationWithExistingDatasetElement="(data) => createRelationWithExistingDatasetElement(data)"
      @onClickDetailsMenu="executeAction"
      @search="(data) => handleSearch(data)"
      @onClickDetailsItem="openNewElementDetails"
      @onClickPreviousElement="goBackToPreviousElementDetails"
    />
    <div
      v-if="isDialogOpened"
      class="EditorWrapper-dialogBox"
    >
      <iob-dialog-box
        title="Your element won’t be saved"
        content="We won’t save your element if you don’t specify a title"
        submit-action="Discard"
        cancel-action="Go back"
        @submitAction="discardElement"
        @cancelAction="closeDialogBox"
      />
    </div>
  </div>
</template>

<script setup>
import { useEditorStore } from 'SRC/piniaStore/editor/editor';
import { useAppStore } from 'SRC/piniaStore/app/app';
import { useDataStore } from 'SRC/piniaStore/data/data';
import { useUsersStore } from 'SRC/piniaStore/users/users';
import { useHierarchyStore } from 'SRC/piniaStore/editor/hierarchy/hierarchy';
import {RELATION_TYPES} from 'SRC/globals/constants';
import { computed, onMounted, ref, watch } from 'vue';
import { useRoute } from 'vue-router';
import utils from 'SRC/views/utils';
import { compareObjects } from 'SRC/utils/utils';

const route = useRoute();
const editorStore = useEditorStore();
const hierarchyStore = useHierarchyStore();
const usersStore = useUsersStore();
const appStore = useAppStore();
const dataStore = useDataStore();
const selectedType = ref(null);
const relationTypes = ref(RELATION_TYPES);
const selectedDataTypeName = ref(null);
const textAttributes = ref({});
const isDialogOpened = ref(false);
const selectedRelation = ref(null);
const previousDatasetElement = ref(null);
const previousElementTimeout = ref(null);
const currentElementTimeOut = ref(null);
const previousTitle = ref(null);

const levels = ref(null);
const backupStringAttributes = ref({});
const datasetElementId = computed(() => editorStore.datasetElementId);
const datasetElement = computed(() => editorStore.selectedDatasetElement);
const dataType = computed(() => editorStore.dataType);
const allUsers = computed(() => usersStore.users);
const currentUser = computed(() => usersStore.currentUser);
const parent = computed(() => hierarchyStore.parent);
const children = computed(() => hierarchyStore.children);
const dependencies = computed(() => hierarchyStore.dependencies);
const eligibleChildren = computed(() => hierarchyStore.eligibleChildren);
const eligibleParents = computed(() => hierarchyStore.eligibleParents);
const eligibleDependencies = computed(() => hierarchyStore.eligibleDependencies);
const eligibleChildDataTypes = computed(() => hierarchyStore.eligibleChildDataTypes);
const eligibleParentDataTypes = computed(() => hierarchyStore.eligibleParentDataTypes);
const getMainPanelAttributes = computed(() => editorStore.dataType.editorConfig.general.mainAttributesPanel);
const getCentralPanelAttributes = computed(() => editorStore.dataType.editorConfig.general.centralAttributesPanel);
const eligibleRelationsDatatypes = computed(() => editorStore.dataType.editorConfig.general.eligibleRelationsDatatypes);
const selectedDataTypeTypes = computed(() => {
  const dataTypeName = selectedDataTypeName.value;
  const dataTypeAttributes = checkIfDatatypeHasTypeAttribute(dataTypeName);
  return dataTypeAttributes && dataTypeAttributes.enum && dataTypeName ? dataTypeAttributes.enum : [];
});
const listAttributes = computed(() => {
  if (editorStore && editorStore.dataType.attributes) {
    return editorStore.dataType.attributes.map((e) =>
      editorStore.dataType.attributes.find(({ name }) => e.name === name)
    );
  }
  return [];
});

onMounted(async () => {
  if (!datasetElementId.value) {
    return;
  }
  previousTitle.value = datasetElement.value?.attributes?.title;
  try {
    await hierarchyStore.fetchEligibleDataTypes();
    await hierarchyStore.fetchHierarchicalRelations();
    await hierarchyStore.fetchDatasetElementDependencies();
    await hierarchyStore.fetchRelationTypes();
  } catch (error) {
    console.error(error);
  }
  backupStringAttributes.value.title = datasetElement.value?.attributes?.title || '';
  levels.value = appStore.levelsTree;
});

watch(
  () => editorStore.previousDatasetElementIds,
  async (ids) => {
    if (ids.length === 0) {
      previousDatasetElement.value = null;
      return;
    }
    const id = ids[ids.length - 1];
    previousDatasetElement.value = await dataStore.fetchDatasetElementById(id);
  }, {immediate: true}
);

const getOwner = (ownerId) => {
  const owner = allUsers.value.find((user) => user.id === ownerId);
  return owner ? owner : currentUser.value;
};

const checkIfDatatypeHasTypeAttribute = (dataTypeName) => {
  const datatype = appStore.getDataTypeByName(dataTypeName);
  return datatype && datatype.attributes.find((attribute) => attribute.name === 'type');
};

const fetchEligibleDatasetElements = async ({ relation, attributes = {}, dataTypeName, searchValue }) => {
  selectedType.value = null;
  selectedDataTypeName.value = dataTypeName;
  selectedRelation.value = relation;
  const typeAttribute = checkIfDatatypeHasTypeAttribute(dataTypeName);

  if (typeAttribute?.enum?.length) {
    selectedType.value = typeAttribute.enum[0].value;
  }

  if (!attributes.type && selectedType.value) {
    attributes.type = selectedType.value;
  }

  if (attributes.type) {
    selectedType.value = attributes.type;
  }

  const datatype = appStore.getDataTypeByName(dataTypeName);
  const searchQuery = searchValue ? `&search=${searchValue}` : '';
  const isDependency = relationTypes.value[2];
  await hierarchyStore.fetchEligibleElementsForRelations(
    {
      type: relation,
      attributes,
      searchQuery,
      ...(isDependency && { dataTypeFilters: {[datatype.id]: {}} })
    }
  );
};

const discardElement = async () => {
  const isDeleted = await dataStore.deleteSelectedDatasetElement(datasetElementId.value);
  if (!isDeleted) {
    return;
  }
  closeDialogBox();
  resetEditorWrapperData();
};

const closeDialogBox = () => {
  isDialogOpened.value = false;
};

const closeEditor = async () => {
  const currentTitle = backupStringAttributes.value?.title;
  if (!currentTitle.length) {
    if (editorStore.isCreating) {
      isDialogOpened.value = true;
      return ;
    } else {
      textAttributes.value.title = previousTitle.value;
      backupStringAttributes.value.title = previousTitle.value;
    }
  }
  if (!compareObjects(datasetElement.value?.attributes, backupStringAttributes.value)) {
    await updateTextInputs();
  }
  await dataStore.addFormulasResultsToElements();
  backupStringAttributes.value = {};
  clearTimeout(previousElementTimeout.value);
  clearTimeout(currentElementTimeOut.value);
  resetEditorWrapperData();
  editorStore.resetPreviousDataEditor();
  editorStore.isCreating = false;
};

async function updateAttrs(attributes) {
  await editorStore.updateDatasetElement(attributes);
  dataStore.updateAttributeInParent(
    datasetElementId.value,
    attributes
  );
  dataStore.updateAttributeInRelatedDatasetElements(
    datasetElementId.value,
    attributes
  );
  await dataStore.addFormulasResultsToElements();
}

async function updateTextInputs() {
  if (Object.keys(textAttributes.value).length > 0) {
    const attributes = {
      ...datasetElement.value.attributes,
      ...textAttributes.value
    };
    await updateAttrs(attributes);
  }
  textAttributes.value = {};
}

async function updateAttributeOnInputChange(attribute, updatedValue) {
  const attributes = {
    ...datasetElement.value.attributes,
    [attribute]: updatedValue
  };
  updateAttrs(attributes);
}

const onTextInput = utils.debounce(updateTextInputs, 1000);
const onInputChange = utils.debounce(updateAttributeOnInputChange, 1000);

const isObject = (obj) => typeof obj === 'object' && obj !== null && !Array.isArray(obj);
const handleAttributesChange = async ({ attribute, value }) => {
  const foundAttribute = listAttributes.value.find((attr) => attr.name === attribute);
  const previousAttrValue = datasetElement.value.attributes[attribute];
  const emptyIntAttrValue = foundAttribute.type === 'int' && value.length === 0;
  const isTimestampedValue = foundAttribute.type === 'timestamped_indicator';
  const isIntAttrValue = foundAttribute.type === 'int';

  if (!isObject(value) && (previousAttrValue === value || emptyIntAttrValue)) {
    return;
  }
  if (foundAttribute.type === 'string' && !foundAttribute.enum) {
    textAttributes.value = { ...textAttributes.value, [attribute]: value };
    backupStringAttributes.value = {...backupStringAttributes.value, ...textAttributes.value};
    onTextInput();
    return;
  }
  let updatedValue = value;
  if (foundAttribute.type === 'level') {
    updatedValue = value.id;
  } else if (isTimestampedValue) {
    updatedValue = {
      value: Number(value),
      timestamp: String(new Date().toISOString()).slice(0, -5)
    };
    onInputChange(attribute, updatedValue);
    return;
  }
  if (isIntAttrValue) {
    onInputChange(attribute, updatedValue);
    return;
  }

  const attributes = {
    ...datasetElement.value.attributes,
    [attribute]: updatedValue
  };
  updateAttrs(attributes);
};

const updateDatasetElements = (newDatasetElement) => {
  if (newDatasetElement) {
    const datasetElements = dataStore.datasetElements;
    const newDatasetElements = {[newDatasetElement.id]: newDatasetElement, ...datasetElements};
    dataStore.selectedDatasetElement = newDatasetElement;
    dataStore.datasetElements = newDatasetElements;
  }
};

const createRelationWithNewDatasetElement = async ({ relation, body }) => {
  const dataTypeId = appStore.getDataTypeByName(body.dataTypeName).id;
  delete body.dataTypeName;
  body = {
    attributes: {
      type: selectedType.value,
      title: body.title,
      owner: currentUser.value.id
    },
    dataTypeId
  };
  let newDatasetElement;
  if (relation === relationTypes.value[0]) {
    return;
  }
  if (relation === relationTypes.value[2]) {
    newDatasetElement = await hierarchyStore.createDependencyWithNewDatasetElement(body);
    if (!shouldUpdateDatasetElements(newDatasetElement)) {
      return;
    }
    updateDatasetElements(newDatasetElement);
    return;
  }
  newDatasetElement = await hierarchyStore.createRelationFromNewElement(body);
  if (!shouldUpdateDatasetElements(newDatasetElement)) {
    return;
  }
  updateDatasetElements(newDatasetElement);
};

const shouldUpdateDatasetElements = (newDatasetElement) =>
  (newDatasetElement && newDatasetElement.typeId === route.params.id) || route.path === '/elements';

const createRelationWithExistingDatasetElement = async ({
  relation,
  eligibleDatasetElementId
}) => {
  if (relation === relationTypes.value[2]) {
    await hierarchyStore.createDependencyWithExistingDatasetElement({
      eligibleDatasetElementId
    });
    return;
  }
  await hierarchyStore.createRelationsFromExistingElements({
    relation,
    eligibleDatasetElementId
  });
};

const executeAction = async ({ action, relatedDatasetElementId, relationId, relationType}) => {
  if (action === 'deleteAction') {
    await hierarchyStore.deleteRelations({relationId, relatedDatasetElementId, relationType});
    return ;
  }
  if (action === 'unlinkAction') {
    await hierarchyStore.unlinkRelationalElements({relationId, relatedDatasetElementId, relationType});
  }
};

const handleSearch = utils.debounce(async (searchValue) => {
  let attributes = {};
  if (selectedType.value) {
    attributes = { type: selectedType.value };
  }
  await fetchEligibleDatasetElements({
    relation: selectedRelation.value,
    attributes,
    dataTypeName: selectedDataTypeName.value,
    searchValue
  });
}, 500);

const openNewElementDetails = ({id}) => {
  editorStore.addPreviousDatasetElement(datasetElementId.value);
  resetEditorWrapperData();
  currentElementTimeOut.value = setTimeout(() => editorStore.openEditor(id));
};

const goBackToPreviousElementDetails = () => {
  resetEditorWrapperData();
  previousElementTimeout.value = setTimeout(() => editorStore.openPreviousDetailsEditor());
};

const resetEditorWrapperData = () => {
  hierarchyStore.resetHierarchicalRelations();
  editorStore.closeEditor();
};
</script>
<style lang="scss" scoped src="./EditorWrapper.scss" />
