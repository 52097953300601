<template>
  <div>
    <IobHeaderAction
      :title="$t('hierarchy.label')"
      :sub-title="hierarchiesCount"
      button-icon="CloudUpload"
      :filter-button="false"
      :sort-button="false"
      :list-view-button="false"
      :has-search-input="false"
      :more-btn="true"
      @click="toggleDropdown"
    />
    <div id="svg-tree" />
    <LevelDetails
      v-if="selectedNode"
      :selected-node="selectedNode"
      :close-right-panel="closeRightPanel"
    />
    <MultiLevelMenu
      v-if="isDropdownVisible"
      :items="menuData"
      class="multiple-level-menu"
      sub-menu-position="left"
      @click="handleClickAction"
    />
    <ModalView
      :is-visible="isModalVisible"
      :title="MODAL_TEXTS.REMOVE_HIERARCHY_TITLE"
      :description="MODAL_TEXTS.REMOVE_HIERARCHY_DESCRIPTION"
      :cancel-button="MODAL_TEXTS.CANCEL_BUTTON"
      :remove-button="MODAL_TEXTS.REMOVE_BUTTON"
      @cancel="hideModal"
      @remove="confirmRemove"
    />
  </div>
</template>

<script>
import {useRouter} from 'vue-router';
import {computed, createApp, h, ref, watch} from 'vue';
import ApexTree from 'apextree';
import LevelDetails from '../LevelDetails/LevelDetails.vue';
import TreeNodeTemplate from '../ApexNodeTemplate/TreeNodeTemplate.vue';
import {useAppStore} from 'SRC/piniaStore/app/app';
import {DATA_TYPES_NAMES, DROPDOWN_MENU_TEXTS, HIERARCHY, MODAL_TEXTS} from 'SRC/globals/constants';
import ModalView from '../ModalView/ModalView.vue';
import templateHierarchy from 'SRC/assets/templates/Template-hierarchy.xlsx';

export default {
  name: 'HierarchyView',
  components: {
    LevelDetails,
    // eslint-disable-next-line vue/no-unused-components
    TreeNodeTemplate,
    ModalView
  },

  setup() {
    const routeChanged = ref(false);
    const hierarchiesCount = ref(1);
    const selectedNode = ref(null);
    const isDropdownVisible = ref(false);
    const isModalVisible = ref(false);

    const router = useRouter();
    const appStore = useAppStore();

    const levels = computed(() => appStore.levels);
    const levelsTree = computed(() => appStore.levelsTree);
    const systemDatatypes = computed(() => appStore.systemDatatypes);

    const toggleDropdown = () => {
      isDropdownVisible.value = !isDropdownVisible.value;
    };

    const closeRightPanel = () => {
      selectedNode.value = null;
    };

    const showModal = () => {
      isDropdownVisible.value = false;
      isModalVisible.value = true;
    };

    const hideModal = () => {
      isModalVisible.value = false;
    };

    const confirmRemove = async () => {
      hideModal();
      try {
        const elementsIds = Object.keys(levels.value);
        await appStore.deleteHierarchy(elementsIds);
        router.push({ path: '/hierarchy-import' });
      } catch (error) {
        console.error('Failed to delete hierarchy:', error);
      }
    };

    const treeNodeOptions = () => ({
      contentKey: 'attributes',
      width: window.innerWidth,
      height: window.innerHeight,
      nodeWidth: 250,
      nodeHeight: 100,
      fontColor: '#5D587B',
      borderColor: '#ffffff',
      childrenSpacing: 50,
      siblingSpacing: 20,
      direction: 'top',
      enableExpandCollapse: true,
      nodeTemplate: (content) => {
        const nodeTemplateWrapper = document.createElement('div');
        const app = createApp({
          render() {
            return h(TreeNodeTemplate, { content });
          }
        });
        app.mount(nodeTemplateWrapper);
        return nodeTemplateWrapper.innerHTML;
      },
      canvasStyle: 'background: #f6f6f6;',
      enableToolbar: true
    });

    const initializeTree = async () => {
      const treeElement = document.getElementById('svg-tree');
      const data = levelsTree.value?.length ? levelsTree.value[0] : undefined;
      if (data) {
        const tree = new ApexTree(treeElement, treeNodeOptions());
        tree.render(data);
        attachClickEvent(treeElement);
      } else {
        router.push({ path: '/hierarchy-import' });
      }
    };

    const attachClickEvent = (element) => {
      element.addEventListener('click', (event) => {
        const clickedNode = event.target.closest('.apex-tree-node');
        if (clickedNode) {
          const nodeId = clickedNode.id;
          const nodeData = Object.values(levels.value).find((element) => element.attributes['level-id'] === nodeId);
          if (nodeData) {
            selectedNode.value = nodeData.attributes;
          }
        } else {
          closeRightPanel();
        }
      });
    };

    watch(() => levelsTree.value,
      async () => {
        await initializeTree();
      }, { deep: true }
    );

    router.beforeEach((to, from, next) => {
      if (to.path === HIERARCHY.VIEW_PATH) {
        routeChanged.value = !routeChanged.value;
      }
      next();
    });

    watch(() => routeChanged.value,
      async () => {
        if (routeChanged.value) {
          await initializeTree();
          routeChanged.value = false;
        }
      }, { deep: true }
    );

    const menuData = {
      0: {
        id: 1,
        title: 'Download',
        iconName: 'Download',
        submenu: {
          0: {
            id: 2,
            title: 'Template (.xls)',
            action: 'exportTemplate'
          },
          1: {
            id: 3,
            title: 'Current hierarchy (.xls)',
            action: 'exportHierarchy'
          }
        }
      },
      1: {
        id: 4,
        title: 'Remove hierarchy',
        action: 'removeHierarchy',
        iconName: 'Trash'
      }
    };

    const excelFileTypes = 'vnd.ms-excel';
    const actions = {
      exportTemplate: async () => {
        await appStore.exportFile(templateHierarchy, 'Template-hierarchy.xlsx', excelFileTypes, true);
      },
      exportHierarchy: async () => {
        const levelDataType = Object.values(systemDatatypes.value).find((element) => element.name === DATA_TYPES_NAMES.LEVEL);
        const dataTypeId = levelDataType ? levelDataType.id : '';
        const exportTemplateUrl = `/dataset-elements/export?dataTypeId=${dataTypeId}`;
        await appStore.exportFile(exportTemplateUrl, 'Hierarchy.xlsx', excelFileTypes);
      },
      removeHierarchy: () => {
        showModal();
      }
    };

    const handleClickAction = async (options) => {
      const action = actions[options.action];
      if (action) {
        await action();
      }
    };

    return {
      menuData,
      handleClickAction,
      hierarchiesCount,
      selectedNode,
      closeRightPanel,
      toggleDropdown,
      isDropdownVisible,
      showModal,
      hideModal,
      isModalVisible,
      confirmRemove,
      MODAL_TEXTS,
      DROPDOWN_MENU_TEXTS
    };
  }

};
</script>

<style lang="scss" src="./HierarchyView.scss" />
