<template>
  <div
    class="IobCard level-container focus-inside elevation-on-hover level-1"
    tabindex="0"
  >
    <div
      class="IobCard-image"
      @click="handleImageClick"
    >
      <iob-image
        :src="isLoading ? null : coverSrc"
        :render-type="coverRenderType"
      />
      <div
        v-if="veilAction?.icon"
        class="overlay"
      >
        <icon-loader
          color="var(--old-app-card-attachment-color-icon, #ffffff)"
          :name="veilAction?.icon"
          size="large"
        />
      </div>
    </div>
    <div class="IobCard-bottom-section">
      <div class="IobCard-bottom-section-info">
        <div class="IobCard-bottom-section-info-subtitle">
          <iob-skeleton
            v-if="isLoading"
            width="50%"
          />
          <span v-else>{{ subTitle }}</span>
        </div>
        <div class="IobCard-bottom-section-info-title-with-status">
          <div
            class="IobCard-bottom-section-info-title-text"
            :class="loadingTitleClass"
          >
            <iob-skeleton
              v-if="isLoading"
              width="100%"
            />
            <span v-else>{{ title }}</span>
          </div>
          <iob-badge
            v-if="!isLoading && status"
            size="s"
            color="secondary"
            type="outlined"
            :label="status"
          />

          <iob-skeleton
            v-if="isLoading"
            width="25%"
            height="24px"
          />
        </div>
        <div
          v-if="badges.length"
          class="IobCard-bottom-section-info-tags"
        >
          <iob-skeleton
            v-for="(_, index) in badges"
            v-show="isLoading"
            :key="index"
            width="80px"
            height="24px"
          />
          <iob-badge
            v-for="badge in badges"
            v-show="!isLoading"
            :key="badge"
            size="s"
            :color="badge.color"
            :label="badge.label"
          />
        </div>
      </div>
      <div
        v-if="showActions && !isLoading"
        class="IobCard-bottom-section-actions"
      >
        <div
          v-for="action in actionsToDisplay?.displayedActions"
          :key="action.icon"
        >
          <iob-action-button
            color="secondary"
            :size="actionsSize"
            type="ghost"
            :icon-name="action.icon"
            @click="handleActionEvent(action.event)"
          />
        </div>
        <div v-if="actionsToDisplay?.dropdownActions.length > 0">
          <iob-action-button
            color="secondary"
            :size="actionsSize"
            type="ghost"
            icon-name="MoreVertical"
            :selected="toggleDropdown"
            @click="handleMoreActionButtonClick"
          />
          <outside-click-listener @outside-click="handleClickOutsideDropdown">
            <iob-dropdown
              v-if="toggleDropdown && actionsToDisplay?.dropdownActions?.length"
              class="IobDropdownButton-button-dropdown"
              :items="actionsToDisplay?.dropdownActions"
              :is-scrollable="true"
              @dropdown-element-item="handleDropdownItemClick"
            />
          </outside-click-listener>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import IobImage from '../../../Atoms/IobImage/IobImage.vue';
import IobBadge from '../../../Atoms/IobBadge/IobBadge.vue';
import IobActionButton from '../../IobActionButton/IobActionButton.vue';
import IobSkeleton from '../../../Atoms/IobSkeleton/IobSkeleton.vue';
import IconLoader from '../../../IconLoader/IconLoader.vue';
import IobDropdown from '../../IobDropdown/IobDropdown.vue';
import OutsideClickListener from '../../../OutsideClickListener/OutsideClickListener.vue';

import { computed, ref, defineEmits } from 'vue';

const props = defineProps({
  coverSrc: {
    type: String,
    default: '',
  },
  coverRenderType: {
    type: String,
    default: '16:9',
    validator: (value) =>
      ['fit-to-box', 'fit-to-img', '1:1', '16:9', '4:3', 'golden'].includes(
        value
      ),
  },
  title: {
    type: String,
    default: '',
  },
  subTitle: {
    type: String,
    default: '',
  },
  showActions: {
    type: Boolean,
    default: true,
  },
  actionsSize: {
    type: String,
    default: 'default',
    validator: (value) => ['xsmall', 'small', 'default', 'large'].includes(value),
  },
  status: {
    type: String,
    default: '',
  },
  badges: {
    type: Array,
    default: () => [],
    validator: (value) =>
      value.every((badge) => 'label' in badge && 'color' in badge),
  },
  isLoading: {
    type: Boolean,
    default: false,
  },
  veilAction: {
    type: Object,
    default: () => {
      return {
        icon: 'Eye',
        event: 'onVeilClick'
      };
    },
  },
  actionsList: {
    type: Array,
    default: () => [],
  },
  numberActionsToDisplay: {
    type: Number,
    default: 2,
  },
});
const toggleDropdown = ref(false);
const emits = defineEmits(['onActionClick', 'onVeilClick']);
const getActions = () => {
  if (!props?.actionsList?.length) {
    return {
      displayedActions: [],
      dropdownActions: [],
    };
  }
  const { actionsList, numberActionsToDisplay } = props;
  let displayLimit = numberActionsToDisplay ?? actionsList.length;

  if (displayLimit < 1) {
    displayLimit = 2;
    console.error('numberActionsToDisplay should be greater than 0');
  }

  return {
    displayedActions: actionsList?.slice(0, Math.max(0, displayLimit - 1)),
    dropdownActions: actionsList?.slice(displayLimit - 1).map((item) => ({
      text: item.text,
      iconName: item.icon,
      event: item.event,
      type: 'menu',
    })),
  };
};
const actionsToDisplay = computed(() => getActions());
const handleMoreActionButtonClick = (event) => {
  event.stopPropagation();
  toggleDropdown.value = !toggleDropdown.value;
};
const handleActionEvent = (event) => {
  emits('onActionClick', event);
};
const handleClickOutsideDropdown = () => {
  toggleDropdown.value = false;
};

const handleDropdownItemClick = ({ item }) => {
  handleActionEvent(item.event);
};

const handleImageClick = () => {
  if (props.veilAction?.event) {
    emits('onVeilClick', props.veilAction.event);
  }
};
const loadingTitleClass = computed(() => ({
  'IobCard-bottom-section-info-title-text--is-loading': props.isLoading,
}));
</script>

<style lang="scss" src="./IobCard.scss" scoped />
<style lang="scss" src="iobeya-design-tokens/scss/app/iobeya-app-cards.scss" />
