<template>
  <div
    class="MainLayout level-container level-0"
    style="position: relative; z-index: 0;"
  >
    <div class="MainLayout-navMenu">
      <NavigationMenu />
    </div>
    <div class="MainLayout-app">
      <div class="MainLayout-app-header">
        <HeaderNav />
      </div>
      <div class="MainLayout-app-content">
        <!-- Main Content Body  -->
        <div class="MainLayout-panel">
          <bulk-action-panel
            v-if="selectedDatasetElements.length > 0"
            :elements-count="selectedDatasetElements.length"
            :elements="selectedDatasetElements"
            style="z-index: 2;"
            text="elements selected"
            action="Delete"
          />
        </div>
        <router-view />
      </div>
      <editor-wrapper v-if="isEditorOpen" />
      <div class="MainLayout-panel">
        <snack-bar
          v-if="notification && notification.show"
          show="true"
          style="z-index: 2;"
          :message="notification.message"
        />
      </div>
    </div>
    <div
      id="general-modal"
    />
  </div>
</template>

<script setup>

import NavigationMenu from 'SRC/views/NavigationMenu/NavigationMenu.vue';
import HeaderNav from 'SRC/views/HeaderNav.vue';
import EditorWrapper from 'SRC/components/Editor/EditorWrapper.vue';
import BulkActionPanel from 'SRC/components/BulkActionPanel/BulkActionPanel.vue';
import SnackBar from 'SRC/components/SnackBar/SnackBar.vue';
import {useDataStore} from 'SRC/piniaStore/data/data';
import {computed, onMounted} from 'vue';
import {useEditorStore} from 'SRC/piniaStore/editor/editor';
import {useAppStore} from 'SRC/piniaStore/app/app';
import {DATA_TYPES_NAMES} from 'SRC/globals/constants';

const dataStore = useDataStore();
const appStore = useAppStore();
const editorStore = useEditorStore();
const isEditorOpen = computed(() => editorStore.isEditorOpen);
const notification = computed(() => appStore.notification);

const selectedDatasetElements = computed(() => dataStore.selectedDatasetElements);
const systemDatatypes = computed(() => appStore.systemDatatypes);

const fetchLevels = async () => {
  try {
    const systemDatatypesArray = Object.values(systemDatatypes.value);
    const levelDataType = systemDatatypesArray.find((element) => element.name === DATA_TYPES_NAMES.LEVEL);
    if (!levelDataType) {
      console.warn('Level data type not found');
      return;
    }

    const levels = await dataStore.fetchPagedData(levelDataType.id);
    if (!levels) {
      console.warn('No levels fetched');
      return;
    }

    appStore.setLevels(levels);
    await appStore.fetchLevelsTree();
  } catch (error) {
    console.error('An error occurred while fetching levels:', error);
  }
};

onMounted(async () => {
  await appStore.fetchElementsDataTypes();
  await fetchLevels();
  await appStore.fetchFeatureToggles();
});

</script>

<style scoped src="./MainLayout.css" />
