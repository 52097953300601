import { evaluate } from '@iobeya/vms-formula-evaluator/evaluator';
import { buildVmsTree } from '@iobeya/vms-formula-evaluator/vmsExprTree';
import DatasetElementContext from '../../views/DataView/DatasetElementContext';

export const evaluateFormula = async (elementData, dataType, formula, allDataTypes) => {
  const evalCtx = new DatasetElementContext({ ...elementData, dataType, allDataTypes});
  try {
    const res = await evaluate(buildVmsTree(formula), evalCtx);
    return res;
  } catch (e) {
    console.error('Error evaluating formula', e);
    return null;
  }
};
