<template>
  <table class="IobTableMapInput IobTableMapInput-table">
    <thead>
      <tr>
        <th />
        <th class="IobTableMapInput-text">
          DATE
        </th>
        <th class="IobTableMapInput-text">
          TARGET
        </th>
        <th class="IobTableMapInput-text">
          ACTUAL VALUE
        </th>
      </tr>
    </thead>
    <tbody>
      <tr
        v-for="({ name, value }, index) in formattedMap"
        :key="name + value"
        class="IobTableMapInput IobTableMapInput-row"
      >
        <td class="IobTableMapInput IobTableMapInput-cellIndex IobTableMapInput-cell--index">
          {{ index + 1 }}
        </td>
        <td class="IobTableMapInput IobTableMapInput-cell IobTableMapInput-cell--disabled">
          {{ name }}
        </td>
        <td class="IobTableMapInput IobTableMapInput-cell">
          <input
            :id="name + value + index"
            :value="value"
            class="IobTableMapInput-input"
            @keydown="(event) => handleKeyDown(event, index)"
            @change="(event) => handleInput({ event, name })"
          >
        </td>
        <td class="IobTableMapInput IobTableMapInput-cell">
          <input
            :id="name + value + index"
            :value="value"
            class="IobTableMapInput-input"
            @keydown="(event) => handleKeyDown(event, index)"
            @change="(event) => handleInput({ event, name })"
          >
        </td>
      </tr>
    </tbody>
  </table>
</template>

<script setup>
import { computed, defineProps, defineEmits } from 'vue';

const emit = defineEmits(['updateRow']);
const props = defineProps({
  map: {
    type: Object,
    default: () => ({}),
  },
  initialValue: {
    type: String,
    default: ''
  },
  finalTarget: {
    type: String,
    default: ''
  },
  horizonValue: {
    type: String,
    default: ''
  }
});

const formattedMap = computed(() => {
  return Object.entries(props.map).map(([name, value]) => ({
    name,
    value
  }));
});

const handleInput = ({event, name}) => {
  const value = event.target.value;
  emit('updateRow', {name, value})
};

const focusAdjacentRow = (adjacentCellIndex) => {
  const adjacentCellData = formattedMap.value[adjacentCellIndex];
  const adjacentCellId = `${adjacentCellData.name}${adjacentCellData.value}${adjacentCellIndex}`;
  const adjacentCell = document.getElementById(adjacentCellId);
  adjacentCell.focus();
};

const handleKeyDown = (event, index) => {
  const nextCellKeys = ['Tab', 'Enter'];
  const shiftKeyOn = event.shiftKey;
  const key = event.key;
  if (key === 'Tab') {
    event.preventDefault();
  }
  if (nextCellKeys.indexOf(key) < 0) {
    return;
  }
  const adjacentRowIndex = (index + (shiftKeyOn ? -1 : 1)) % formattedMap.value.length;
  focusAdjacentRow(adjacentRowIndex);
}
</script>
<style lang="scss" scoped src="./IobTimeSeriesTableMapInput.scss" />
