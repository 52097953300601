export function generateMonthsKpi(startDate, endDate, timeSeriesDate) {
  const dates = [];
  let currentDate = new Date(startDate);

  const timeSeriesKey = Array.isArray(timeSeriesDate) ? timeSeriesDate[0] : timeSeriesDate;

  while (currentDate <= endDate) {
    let monthDate;
    const month = currentDate.getMonth();
    const year = currentDate.getFullYear();

    switch (timeSeriesKey) {
    case 'First day of the month':
      monthDate = new Date(year, month, 1);
      break;
    case '15th of the month':
      monthDate = new Date(year, month, 15);
      break;
    case 'Last day of the month':
      monthDate = new Date(year, month + 1, 0);
      break;
    case 'First Monday of the month':
      monthDate = getNthDayOfWeek(year, month, 1, 1);
      break;
    case 'Last Friday of the month':
      monthDate = getLastDayOfWeek(year, month, 5);
      break;
    default:
      monthDate = new Date(year, month, 1);
    }

    if (monthDate <= endDate) {
      const dayName = monthDate.toLocaleDateString('en-us', { weekday: 'long' });
      const day = monthDate.getDate();
      const monthString = monthDate.toLocaleDateString('en-us', { month: 'long' });
      dates.push(`${dayName} of the ${day} of ${monthString}`);
    }

    currentDate.setMonth(currentDate.getMonth() + 1);
  }

  return dates;
}

export function generateYearsKpi(startDate, endDate, timeSeriesDate) {
  const dates = [];
  let currentYear = startDate.getFullYear();

  const timeSeriesKey = Array.isArray(timeSeriesDate) ? timeSeriesDate[0] : timeSeriesDate;

  while (currentYear <= endDate.getFullYear()) {
    let yearDate;

    switch (timeSeriesKey) {
    case 'First day of the year':
      yearDate = new Date(currentYear, 0, 1);
      break;
    case 'Last day of the year':
      yearDate = new Date(currentYear, 11, 31);
      break;
    case 'First Monday of the year':
      yearDate = getNthDayOfWeek(currentYear, 0, 1, 1);
      break;
    case 'Last Friday of the year':
      yearDate = getLastDayOfWeek(currentYear, 11, 5);
      break;
    default:
      yearDate = new Date(currentYear, 0, 1);
    }

    const dayName = yearDate.toLocaleDateString('en-us', { weekday: 'long' });
    const day = yearDate.getDate();
    const monthString = yearDate.toLocaleDateString('en-us', { month: 'long' });
    dates.push(`${dayName} of the ${day} of ${monthString}`);

    currentYear++;
  }

  return dates;
}

export function generateQuartersKpi(startDate, endDate, timeSeriesDate) {
  const quarters = [];
  let currentDate = new Date(startDate);

  const timeSeriesKey = Array.isArray(timeSeriesDate) ? timeSeriesDate[0] : timeSeriesDate;

  while (currentDate <= endDate) {
    const year = currentDate.getFullYear();
    const month = currentDate.getMonth();
    let quarterDate;

    const quarterStartMonth = Math.floor(month / 3) * 3;

    switch (timeSeriesKey) {
    case 'First day of the quarter':
      quarterDate = new Date(year, quarterStartMonth, 1);
      break;

    case 'Last day of the quarter':
      quarterDate = new Date(year, quarterStartMonth + 3, 0);
      break;

    case 'First Monday of the quarter':
      quarterDate = getNthDayOfWeek(year, quarterStartMonth, 1, 1);
      break;

    case 'Last Friday of the quarter':
      quarterDate = getLastDayOfWeek(year, quarterStartMonth + 2, 5);
      break;

    default:
      quarterDate = new Date(year, quarterStartMonth, 1);
    }

    if (quarterDate >= startDate && quarterDate <= endDate) {
      const dayName = quarterDate.toLocaleDateString('en-us', { weekday: 'long' });
      const day = quarterDate.getDate();
      const monthString = quarterDate.toLocaleDateString('en-us', { month: 'long' });
      quarters.push(`${dayName} of the ${day} of ${monthString}`);
    }

    currentDate.setMonth(currentDate.getMonth() + 3);
  }

  return quarters;
}

export function generateBiannual(startDate, endDate, timeSeriesDate) {
  const dates = [];
  const timeSeriesKey  = Array.isArray(timeSeriesDate) ? timeSeriesDate[0] : timeSeriesDate;

  const semesters = [
    { startMonth: 0, endMonth: 5 },
    { startMonth: 6, endMonth: 11 }
  ];

  let currentYear = startDate.getFullYear();

  while (currentYear <= endDate.getFullYear()) {
    semesters.forEach((semester) => {
      let semesterDate;

      switch (timeSeriesKey) {
      case 'First day of the semester':
        semesterDate = new Date(currentYear, semester.startMonth, 1);
        break;
      case 'Last day of the semester':
        semesterDate = new Date(currentYear, semester.endMonth + 1, 0);
        break;
      case 'First Monday of the semester':
        semesterDate = getNthDayOfWeek(currentYear, semester.startMonth, 1, 1);
        break;
      case 'Last Friday of the semester':
        semesterDate = getLastDayOfWeek(currentYear, semester.endMonth, 5);
        break;
      default:
        semesterDate = new Date(currentYear, semester.startMonth, 1);
      }

      if (semesterDate >= startDate && semesterDate <= endDate) {
        const dayName = semesterDate.toLocaleDateString('en-us', { weekday: 'long' });
        const day = semesterDate.getDate();
        const monthString = semesterDate.toLocaleDateString('en-us', { month: 'long' });
        dates.push(`${dayName} of the ${day} of ${monthString}`);
      }
    });

    currentYear++;
  }

  return dates;
}

export function generateWeeksKpi(startDate, endDate, timeSeriesDate) {
  const daysOfWeekMap = {
    'Every Monday': 1,
    'Every Tuesday': 2,
    'Every Wednesday': 3,
    'Every Thursday': 4,
    'Every Friday': 5,
    'Every Saturday': 6,
    'Every Sunday': 0
  };

  const timeSeriesKey = Array.isArray(timeSeriesDate) ? timeSeriesDate[0] : timeSeriesDate;
  const targetDay = daysOfWeekMap[timeSeriesKey];

  if (targetDay === undefined) {
    return [];
  }

  let weeks = [];
  let currentDate = new Date(startDate);

  while (currentDate <= endDate) {
    if (currentDate.getDay() === targetDay) {
      const day = currentDate.getDate();
      const month = currentDate.toLocaleString('default', { month: 'long' });

      weeks.push(`${timeSeriesKey.split(' ')[1]} ${day} of ${month}`);
    }

    currentDate.setDate(currentDate.getDate() + 1);
  }

  return weeks;
}

export function getNthDayOfWeek(year, month, n, dayOfWeek) {
  const firstDay = new Date(year, month, 1);
  let dayOffset = dayOfWeek - firstDay.getDay();
  if (dayOffset < 0) dayOffset += 7;
  return new Date(year, month, 1 + dayOffset + (n - 1) * 7);
}

export function getLastDayOfWeek(year, month, dayOfWeek) {
  const lastDay = new Date(year, month + 1, 0);
  let dayOffset = lastDay.getDay() - dayOfWeek;
  if (dayOffset < 0) dayOffset += 7;
  return new Date(year, month + 1, 0 - dayOffset);
}

export const getTimeseriesFrequencyAndDate = (timeSeriesFrequency) => {
  const [frequencyTimeSeries, timeSeriesDate] = timeSeriesFrequency.split(':').map(part => part.trim());
  return { frequencyTimeSeries, timeSeriesDate };
};