<template>
  <div
    v-if="isVisible"
    class="ModalView-overlay"
  >
    <div class="ModalView-content">
      <p class="ModalView-title">
        {{ title }}
      </p>
      <p class="ModalView-description">
        {{ description }}
      </p>
      <div class="ModalView-actions">
        <button
          class="ModalView-cancel-btn"
          @click="cancel"
        >
          {{ cancelButton }}
        </button>
        <button
          class="ModalView-remove-btn"
          @click="remove"
        >
          {{ removeButton }}
        </button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    isVisible: {
      type: Boolean,
      required: true
    },
    title: {
      type: String,
      default: ''
    },
    description: {
      type: String,
      default: ''
    },
    cancelButton: {
      type: String,
      default: ''
    },
    removeButton: {
      type: String,
      default: ''
    }
  },
  methods: {
    cancel() {
      this.$emit('cancel');
    },
    remove() {
      this.$emit('remove');
    }
  }
};
</script>

<style lang="scss" scoped src="./ModalView.scss" />

