import { defineStore } from 'pinia';
import { useAppStore } from '../app/app';
import { useDataStore } from '../data/data';
import { useUsersStore } from '../users/users';
import { put } from '../../api/index';
import { getUserInitials } from 'SRC/utils/utils';

function createRelationObject(datasetElement, titleAttributeName) {
  const usersStore = useUsersStore();
  const user = datasetElement.attributes.owner ? usersStore.getUserInfo(datasetElement.attributes.owner) : null;
  return {
    ...datasetElement,
    id: datasetElement.id,
    typeId: datasetElement.typeId,
    friendlyId: datasetElement.attributes['friendly-id'],
    title: datasetElement.attributes[titleAttributeName],
    attributes: datasetElement.attributes,
    ownerPicture: user ? user.picture : null,
    ownerName: user ? getUserInitials(user.firstname, user.lastname) : null
  };
}

export const useEditorStore = defineStore('editor', {
  state: () => ({
    dataType: null,
    datasetElementId: null,
    isEditorOpen: false,
    previousDatasetElementIds: [],
    selectedDatasetElement: null,
    isCreating: false
  }),
  actions: {
    async initEditor(datasetElementId) {
      this.datasetElementId = datasetElementId;
      const appStore = useAppStore();
      const dataStore = useDataStore();
      let datasetElement = dataStore.datasetElements[datasetElementId];
      if (!datasetElement) {
        datasetElement = await dataStore.fetchDatasetElementById(datasetElementId);
      }
      this.selectedDatasetElement = datasetElement;
      this.dataType = appStore.datatypes[datasetElement.typeId];
    },
    resetEditor() {
      this.dataType = null;
      this.datasetElementId = null;
    },
    resetPreviousDataEditor() {
      this.previousDatasetElementIds = [];
    },
    closeEditor() {
      this.isEditorOpen = false;
      this.resetEditor();
    },
    async openEditor(datasetElementId) {
      this.initEditor(datasetElementId);
      this.isEditorOpen = true;
    },
    async openPreviousDetailsEditor() {
      const previousDatasetElementId = this.previousDatasetElementIds.pop();
      if (!previousDatasetElementId) {
        return ;
      }
      this.openEditor(previousDatasetElementId);
    },
    async updateDatasetElement(attributes) {
      const dataStore = useDataStore();
      const datasetElement = dataStore.datasetElements[this.datasetElementId] || this.selectedDatasetElement;
      attributes = {...datasetElement.attributes, ...attributes};
      const computedByFormulaAttrsList = this.dataType.attributes.filter((attr) => attr.computedByFormula).map((attr) => attr.name);
      computedByFormulaAttrsList.forEach((attrName) => {
        delete attributes[attrName];
      });
      const newBody = Object.assign({}, datasetElement, {attributes, children: {}});
      delete newBody.children;
      const updatedDatasetElement = await put(`/dataset-elements/${this.datasetElementId}`, newBody);
      if (!updatedDatasetElement) {
        return;
      }
      this.selectedDatasetElement = updatedDatasetElement;
      dataStore.updateDatasetElements(updatedDatasetElement);
    },
    async addPreviousDatasetElement(datasetElementId) {
      this.previousDatasetElementIds.push(datasetElementId);
    }
  },

  getters: {
    getRelationObject() {
      const appStore = useAppStore();
      return (datasetElement) => {
        const titleAttribute = appStore.getTitleAttribute(datasetElement.typeId);
        return createRelationObject(datasetElement, titleAttribute.name);
      };
    }
  }
});
