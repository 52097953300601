<template>
  <div
    v-if="props.attachments?.length"
    class="IobAttachmentsList"
  >
    <div class="IobAttachmentsList-header">
      <span class="IobAttachmentsList-title">{{ title }}</span>
      
      <IobDropdownButton
        class="IobAttachmentsList-button"
        data-test-id="IobAttachmentsList-button"
        dropdown-style="postion: absolute; left:unset; right: 0; width: 276px;"
        icon-name="Plus"
        size="small"
        title=""
        :disabled="props.attachments?.length >= MAX_ATTACHMENTS"
        :items="props.dropdownItems"
        :show-right-icon="false"
        :type-check="false"
        @dropdownElementClick="(elem) => emits('onClick:dropdownElement', { element: elem })"
        @update:modelValue="(value, fieldId) => emits('onChange:fieldValue', { value, fieldId })"
      />
    </div>

    <div class="IobAttachmentsList-list">
      <IobCard
        v-for="attachment in props.attachments" 
        :key="attachment.id"
        class="IobAttachmentsList-card"
        :title="attachment.title"
        :sub-title="attachment.createdOn"
        :show-actions="true"
        actions-size="small"
        :cover-src="attachment.type === 'url' ? defaultLinkImage : attachment.url"
        :cover-render-type="'4:3'"
        :veil-action="props.configMap?.[attachment.type]?.veilAction"
        :number-actions-to-display="props.configMap?.[attachment.type]?.numberActionsToDisplay"
        :actions-list="props.configMap?.[attachment.type]?.actionsList"
        @onActionClick="(event) => emits('onClick:action', { event, attachment })"
        @onVeilClick="(event) => emits('onClick:veil', { event, attachment })"
      />
    </div>
  </div>
</template>

<script setup>
import { computed, defineProps, defineEmits } from 'vue';
import IobCard from '../Card/IobCard/IobCard.vue';
import IobDropdownButton from '../IobDropdownButton/IobDropdownButton.vue';
import { MAX_ATTACHMENTS } from '../../../constants';
import { defaultLinkImage } from './defaultLinkImage';

const props = defineProps({
  attachments: {
    type: Array,
    required: true,
    default: () => []
  },
  configMap: {
    type: Object,
    required: true,
    default: () => {}
  },
  dropdownItems: {
    type: Array,
    required: true,
    default: () => []
  }
});
const emits = defineEmits(['onClick:dropdownElement', 'onClick:action', 'onClick:veil', 'onChange:fieldValue']);

const title = computed(() => `Attachments ・ ${props.attachments.length}`); // TODO: Locolize

</script>

<style lang="scss" src="./IobAttachmentsList.scss" scoped />
