import {UNASSIGNED} from 'GLOBALS/constants';

export const initializeHierarchyColors = (hierarchy, colors, index = 0, isFirstLevel = true) => {
  if (!hierarchy) {
    return;
  }
  if (isFirstLevel) {
    hierarchy.attributes.colors = colors[index % colors.length];
    index++;
  }
  hierarchy.children.forEach((child) => {
    child.attributes.colors = colors[index % colors.length];
    initializeHierarchyColors(child, colors, index + 1, false);
  });
};
const findLevelInTree = (levels, id) => {
  for (const level of levels) {
    if (level.id === id) {
      return level;
    }
    if (level.children) {
      const found = findLevelInTree(level.children, id);
      if (found) {
        return found;
      }
    }
  }
  return null;
};
export const getLevelAttributeValue = (levels, id) => {
  const levelName = 'level-name';
  if (levels?.length) {
    const level = findLevelInTree(levels, id);
    if (level) {
      return {
        id: level.id,
        [levelName]: level.attributes[levelName],
        colors: level.attributes.colors || {}
      };
    }
  }
  return {
    [levelName]: UNASSIGNED
  };
};

export const truncateText = (text, maxLength) => {
  if (!text) {
    return '';
  }
  return text.length > maxLength ? `${text.substring(0, maxLength)}...` : text;
};

const utils = {
  debounce: (func, delay) => {
    let debounceTimer;
    return function (...args) {
      const context = this;
      clearTimeout(debounceTimer);
      debounceTimer = setTimeout(() => {
        func.apply(context, args);
      }, delay);
    };
  },
  initializeHierarchyColors,
  truncateText
};
export default utils;
