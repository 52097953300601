<template>
  <div class="HomePage content-container">
    <div class="HomePage-header content-header">
      <iob-header-action
        :title="`${$t('home.hello')} ${userfirstname} &#128075;`"
        :grid-view-button="false"
        :list-view-button="false"
        :filter-button="false"
        :sort-button="false"
        :show-button="false"
        :has-search-input="false"
        :badge-text="levelBadge.text"
        :badge-color="levelBadge.color"
        :badge-tree-list="appStore.levelsTree"
        :badge-tree-status="selectedLevel"
        badge-label-field="level-name"
        @badgeChange="(value) => levelChanged(value)"
      />
    </div>
    <div class="content-body-container">
      <div class="HomePage-body content-body">
        <div class="HomePage-body-image-container">
          <img
            class="HomePage-body-image"
            :src="homeImage"
            alt="home"
          >
        </div>
        <div class="HomePage-body-section">
          <div class="HomePage-body-title-section">
            <div class="HomePage-body-title-text">
              My work
            </div>
          </div>
          <div class="HomePage-body-list">
            <iob-button-elevation
              v-for="(myWork) in myWorksList"
              :key="myWork.id"
              :action="myWork.id"
              :title-number="myWork.counter"
              :title="myWork.title"
              :subtitle="myWork.subtitle"
              :icon-color="myWork.iconColor"
              :icon-name="myWork.iconName"
              @action-select="handleMyWorkAction"
            />
          </div>
        </div>
        <div class="HomePage-body-section">
          <div class="HomePage-body-title-section">
            <div class="HomePage-body-title-text">
              {{ $t('home.routines.label') }}
            </div>
            <div class="HomePage-body-title-button">
              <iob-button
                show-right-icon
                right-icon="Plus"
                @click="showRoutineModal"
              />
            </div>
          </div>
          <div
            v-if="!isLoading && !routineList.length"
            class="HomePage-body-routine--noData"
          >
            <div class="HomePage-body-routine--noData-image" />
            <span class="HomePage-body-routine--noData-text">{{ t(userLevel ? 'routines.noRoutine' : 'routines.shouldSelectLevel') }}</span>
          </div>
          <div class="HomePage-body-list">
            <template v-if="isLoading && !routineList.length">
              <IobRoutineCard
                v-for="index in skelatonCount"
                :key="index"
                is-loading
              />
            </template>

            <IobRoutineCard
              v-for="(card, index) in routineList"
              :key="index"
              :badge-text="card.badgeText"
              :title="card.title"
              :subtitle="card.subtitle"
              :ghost="false"
              :icon-kanji="card.iconKanji"
              :icon-name="card.iconName"
              :badge-color="card.badgeColor"
              :icon-color="card.iconColor"
              :actions="routineActions"
              @click="() => openURL(card.url)"
              @action-select="(index) => handleActionSelect(index, card.id)"
            />
          </div>
        </div>
      </div>
    </div>
    <routine-modal
      v-if="isRoutineModalVisible"
      :edit-enabled="routineEditEnabled"
      :routine="routineEditObject"
      :user-level="userLevel"
      @cancel="hideRoutineModal"
    />
    <iob-dialog-box
      v-if="selectedRoutine"
      :title="$t('routines.deleteRoutine')"
      :content="$t('routines.access')"
      :submit-action="$t('routines.delete')"
      :cancel-action="$t('routines.cancel')"
      @submitAction="deleteRoutine(selectedRoutine); selectedRoutine = null"
      @cancelAction="selectedRoutine = null"
    />
  </div>
</template>

<script setup>
import { useUsersStore } from 'SRC/piniaStore/users/users';
import { ref, computed, watch, onMounted } from 'vue';
import homeImage from 'SRC/assets/illustrations/home.png';
import { useAppStore } from 'SRC/piniaStore/app/app';
import { useDataStore } from 'SRC/piniaStore/data/data';
import RoutineModal from './RoutineModals/RoutineModal.vue';
import { ROUTINE_ICONS, UNCOMPLETED_STATUS_LIST, HIERARCHY, MY_WORKS } from 'SRC/globals/constants';
import { useI18n } from 'vue-i18n';
import { useRouter } from 'vue-router';
import { getHistoryTraces, getNext7Days } from './DataView/utils';
import { useMyWorkStore } from 'SRC/piniaStore/myWork/myWork';
const skelatonCount = 4;
const { t } = useI18n();
const router = useRouter();
const usersStore = useUsersStore();
const appStore = useAppStore();
const dataStore = useDataStore();
const statusValues = computed(() => UNCOMPLETED_STATUS_LIST.map((status) => status.value));
const issueId =  computed(() => dataTypes.value.find((datatype) => datatype.name === 'Issue')?.id);
const searchAttributes = computed(() => {
  const dates = getNext7Days().dates;
  return {
    due: {
      path: '/elements',
      quickFilters: {
        'due-date': dates,
        owner: [currentUserId.value],
        status: statusValues.value
      }
    },
    escalations: {
      path: `/elements/${issueId.value}`,
      quickFilters: {
        'escalation-owner': [currentUserId.value],
        status: statusValues.value
      },
      otherFilters: {
        'escalated-on': ['last_7days']
      }
    }
  };
});
const userfirstname = computed(() => usersStore.currentUser?.firstname || '');
const currentUserId = computed(() => usersStore.currentUser?.id || '');
const userLevel = ref(localStorage.getItem('userLevel') || null);
const levelsTree = computed(() => appStore.levelsTree);
const levels = computed(() => Object.values(appStore.levels).reduce((levelsMap, level) => {
  const levelInTree = findLevelInTree(levelsTree.value, level.id);
  const bgColor = levelInTree ? levelInTree.attributes.colors.bgColor : '';
  const color = HIERARCHY.LEVELS_COLORS.find((color) => color.bgColor === bgColor);
  levelsMap.set(
    level.id,
    {
      text: level.attributes['level-name'],
      bgColor: color?.badgeColor ?? 'primary'
    }
  );
  return levelsMap;
}, new Map()));
const selectedLevel = computed(() => findLevelInTree(levelsTree.value, userLevel.value) || {});
const findLevelInTree = (tree, levelId) => {
  for (const level of tree) {
    if (level.id === levelId) {
      return level;
    }
    if (level.children) {
      const levelFound = findLevelInTree(level.children, levelId);
      if (levelFound) {
        return levelFound;
      }
    }
  }
  return null;
};
const levelChanged = (level) => {
  userLevel.value = level.id;
  fetchRoutines();
  localStorage.setItem('userLevel', level.id);
};
const isLoading = ref(true);
const isRoutineModalVisible = ref(false);
const routineEditEnabled = ref(false);
const routineEditObject = ref({});
const myWorksList = ref(MY_WORKS);
const deleteRoutine = (id) => dataStore.deleteDatasetElement(id);
const routineDataTypeID = computed(() => Object.values(appStore.systemDatatypes).find((datatype) => datatype.name.toLowerCase() === 'routine')?.id);
const routineList = computed(() => Object.values(dataStore.datasetElements).reduce((list, datatype) => {
  if (datatype.typeId === routineDataTypeID.value) {
    list.push({
      title: datatype.attributes.name,
      subtitle: datatype.attributes.description,
      badgeText: levels.value.get(datatype.attributes.level)?.text || '',
      badgeColor: levels.value.get(datatype.attributes.level)?.bgColor,
      iconKanji: ROUTINE_ICONS[datatype.attributes.icon]?.backgroundImg,
      iconColor: ROUTINE_ICONS[datatype.attributes.icon]?.color,
      iconName: datatype.attributes.icon,
      url: datatype.attributes.url,
      id: datatype.id
    });
  }
  return list;
}, []).sort((a, b) => a.title.localeCompare(b.title)));
const selectedRoutine = ref(null);
const levelBadge = computed(() => {
  const level = userLevel.value ? levels.value.get(userLevel.value) : null;
  return {
    text: level?.text || t('routines.selectLevel'),
    color: level?.bgColor || 'secondary'
  };
});

const routineActions = [{
  text: t('routines.edit'),
  iconName: 'SquarePen',
  type: 'menu'
},
{
  text: t('routines.delete'),
  iconName: 'Trash',
  type: 'menu'
}];

function handleActionSelect(index, routineId) {
  if (index === 0) {
    if (routineId in dataStore.datasetElements) {
      const routine = { routineId, ...dataStore.datasetElements[routineId].attributes };
      showRoutineModal({editEnabled: true, routine});
    }
  } else if (index === 1) {
    selectedRoutine.value = routineId;
  }
}

const showRoutineModal = ({editEnabled = false, routine = {}} = {}) => {
  routineEditEnabled.value = editEnabled;
  routineEditObject.value = routine;
  isRoutineModalVisible.value = true;
};

const hideRoutineModal = () => {
  isRoutineModalVisible.value = false;
};

const removeRoutinesFromDataStore = () => {
  const datasetElements = Object.values(dataStore.datasetElements);
  const elements = {};
  datasetElements.forEach((element) => {
    if (element.typeId !== routineDataTypeID.value) {
      elements[element.id] = element;
    }
  });
  dataStore.datasetElements = elements;
};

const fetchRoutines = async () => {
  isLoading.value = true;
  removeRoutinesFromDataStore();
  if (routineDataTypeID.value && userLevel.value) {
    await dataStore.searchDatasetElements(
      {
        query: '',
        dataTypeFilters: {
          [routineDataTypeID.value]: {
            attributes: {
              level: [userLevel.value]
            }
          }
        }
      });
  }
  isLoading.value = false;
};

watch(appStore.systemDatatypes, fetchRoutines);

onMounted(() => {
  fetchRoutines();
});

const myWorkStore = useMyWorkStore();
const currentDate = new Date();
const dataTypes = computed(() => Object.values(appStore.datatypes));
watch(
  [searchAttributes, currentUserId, dataTypes.value],
  async ([newSearchAttributes, newCurrentUserId]) => {
    if (newSearchAttributes && newCurrentUserId) {
      let historyTraces ;
      Object.keys(newSearchAttributes).forEach(async (key) => {
        if (key === 'escalations' && dataTypes.value) {
          historyTraces = getHistoryTraces('escalated-on', ['last_7days'], dataTypes.value, currentDate);
        }
        await myWorkStore.searchDatasetElements({
          query: '',
          attributes: newSearchAttributes[key].quickFilters,
          historyTraces
        });
        myWorksList.value[key].counter = myWorkStore.counter;
        myWorksList.value[key].path = newSearchAttributes[key].path;
        dataStore.quickFilters[key] = newSearchAttributes[key].quickFilters;
        dataStore.otherFilters[key] = newSearchAttributes[key].otherFilters;
      });

      isLoading.value = false;
    }
  },
  { immediate: true, deep: true }
);
const handleMyWorkAction = (action) => {
  if (!myWorksList.value[action].counter) {
    return;
  }
  router.push({ path: myWorksList.value[action].path,
    query: {filter: action}
  });

};
const openURL = (url) => {
  window.open(url, '_blank');
};

</script>

<style lang="scss" scoped>
.HomePage {

  &-body {

    &-image {
      width: 100%;
      height: 100%;
      object-fit: cover;
      border-radius: 10px;

      &-container {
        width: 100%;
        height: 300px;
      }
    }

    &-section {
      display:flex;
      flex-direction: column;
      gap:16px;
    }

    &-title {
      &-section {
        display: flex;
        justify-content: space-between;
        align-items: center;
        align-self: stretch;
      }

      &-text {
        overflow: hidden;
        color: var(--app-section-color-title, #1C1A25);
        text-overflow: ellipsis;
        font-family: "Markazi Text", sans-serif;
        font-size: 32px;
        font-style: normal;
        font-weight: 600;
        line-height: 32px;
        letter-spacing: 0.32px;
      }
    }
    &-list {
      display: grid;
      gap: 16px;
      }
        &-routine {

          &--noData {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            gap: var(--app-content-empty-page-gap-content, 24px);
            align-self: stretch;

            &-text {
              color: var(--app-content-empty-page-color-text);

              font-family: var(--font-family-sans-serif, "Noto Sans");
              font-size: var(--font-size-l);
              font-style: normal;
              font-weight: 600;
              line-height: 28px;
              letter-spacing: -0.18px;
            }

            &-image {
              background: url('../assets/illustrations/noData.svg');
              width: 300px;
              height: 278px;
            }
          }
    }
    &-create-routine {
      width: 344px;
      height: 148px;
      cursor: pointer;
    }
  }
}

/* Large Desktop */
@media (min-width: 1420px) {
  .HomePage-body-list {
    grid-template-columns: repeat(4, 1fr);
  }
}

/* Desktop */
@media (min-width: 1024px) and (max-width: 1419.99px) {
  .HomePage-body-list {
    grid-template-columns: repeat(3, 1fr);
  }
}

/* Tablet */
@media (min-width: 640px) and (max-width: 1023.99px) {
  .HomePage-body-list {
    grid-template-columns: repeat(2, 1fr);
  }
}

/* Mobile */
@media (max-width: 639.99px) {
  .HomePage-body-list {
    grid-template-columns: repeat(1, 1fr);
  }
}

</style>
